import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box, Fade } from '@mui/material';
import axios from 'axios'

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { getFormattedMediumDate, getFormattedSmallDate } from '../utils'
import { IonSpinner } from '@ionic/react';
import StandardCenterCenter from '../commonComponents/StandardCenterContainer';
import { colors } from '../theme/colors';

const styles = {
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}


export default function StepPickDateTime({ businessId, updateScheduleData, step, stepNext, isWalkin, scheduleData, trackStep }) {
    console.log("Render StepPickDateTime")
    const [dateAndTimeData, setDateAndTimeData] = useState()
    const [anyProfessionalDateAndTimeData, setAnyProfessionalDateAndTimeData] = useState({ loading: false, data: null })
    const [value, setValue] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(true);

    function updateTime(dateValue) {
        setValue(dateValue)
        setIsLoading(true)
        setDateAndTimeData()
        setAnyProfessionalDateAndTimeData({ loading: true, data: null })
    }

    useEffect(() => {
        trackStep("PickDateTime", step, scheduleData)
    }, [])

    useEffect(() => {
        getAvailability()
        getAnyProfessionalAvaialbility()
    }, [value])

    const getAvailability = async () => {
        let services = scheduleData?.services.map(service => service._id);
        if (!isWalkin) {
            let response = await axios.get(`/appointment/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${scheduleData?.anyProfessional ? 'undefined' : scheduleData?.barber?._id}&durationMin=${scheduleData?.durationMin}&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${scheduleData?.anyProfessional}&services=${services}`)
            setDateAndTimeData(response.data)
            setIsLoading(false)
        }

    }

    const getAnyProfessionalAvaialbility = async () => {
        let services = scheduleData?.services.map(service => service._id);

        if (!isWalkin && !scheduleData?.anyProfessional) {
            let response2 = await axios.get(`/appointment/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${'undefined'}&durationMin=${scheduleData?.durationMin}&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${true}&services=${services}`)
            setAnyProfessionalDateAndTimeData({ loading: false, data: response2.data })
        } else {
            setAnyProfessionalDateAndTimeData({ loading: false, data: null })
        }

    }

    function handleTimeClick(data, isAnyProfessional) {
        if (isAnyProfessional) {
            updateScheduleData('dateTimeAnyProfessional', data)
        } else {
            updateScheduleData('dateTime', data)
        }

        stepNext()
    }


    return (
        <>
            <Fade in={true}>
                <div>
                    {/* <HeaderComponent title={'Pick a time'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
                    <DateTimeBody value={value} setValue={updateTime} dateAndTimeData={dateAndTimeData} handleTimeClick={handleTimeClick} isLoading={isLoading} anyProfessionalDateAndTimeData={anyProfessionalDateAndTimeData} barber={scheduleData?.barber} />
                </div>
            </Fade>
        </>
    );
}

function DateTimeBody({ value, setValue, dateAndTimeData, handleTimeClick, isLoading, anyProfessionalDateAndTimeData, barber }) {
    let subHeader = <div style={styles.loadingBox}><IonSpinner name='dots' /></div>

    if (!isLoading) {
        subHeader = dateAndTimeData?.outOfBounds == true
            ? "SCHEDULE NOT AVAILABLE YET"
            : (dateAndTimeData?.locationOpen ? "OPEN" : "CLOSED")

    }

    let mainDateTimeMessage;
    if (dateAndTimeData?.availableTimes?.length == 0) {
        mainDateTimeMessage = `No available appointments for ${barber?.firstName || "any professional"}. Please check another day or check below for times from another professional.`
    }

    return (
        <>
            <Box style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginBottom: 30 }}>


                {/* <div>{value.toISOString()}</div> */}
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={value}
                            minDate={new Date()}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    {/* {JSON.stringify(value)} */}
                    {/* MORNING  */}
                    <Box style={{ width: 300, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: -10 }}>
                        <Grid container style={{ display: 'flex', justifyContent: 'left', marginBottom:-20 }}>
                            <Grid item >
                                <p style={{ marginTop: 0 }}>{getFormattedSmallDate(value)}</p>
                                {isLoading ? <div style={styles.loadingBox}><IonSpinner name='dots' /></div> : null}
                            </Grid>
                        </Grid>
                        {subHeader == 'CLOSED' ? <>
                            <StandardCenterCenter>
                                <h5 style={{ marginTop: 0 }}>{subHeader}</h5>
                                <Closed />
                            </StandardCenterCenter>
                        </> : null}

                        <>
                            {subHeader == 'OPEN' ?
                                <Grid container style={{ display: 'flex', justifyContent: 'left' }}>
                                    <Grid item >
                                        <h6 style={{}}>Availability for {barber?.firstName || "Any Professional"}</h6>
                                        <MainDateMessage message={mainDateTimeMessage}/>
                                    </Grid>
                                </Grid>
                                : null}
                            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item container style={{ display: 'flex' }}>
                                    {dateAndTimeData?.availableTimes?.map(timeSlot => (
                                        <TimeSlotButton available={timeSlot.available} timeSlot={timeSlot} handleTimeClick={handleTimeClick} key={timeSlot.startTimeText} />
                                    )) || null}
                                </Grid>
                            </Grid>
                        </>

                        {/* {JSON.stringify(anyProfessionalDateAndTimeData?.data?.availableTimes?.[3])} */}
                        {
                            anyProfessionalDateAndTimeData?.data && anyProfessionalDateAndTimeData?.data?.availableTimes?.length > 0 ?
                                <>
                                    <Grid container style={{ display: 'flex', justifyContent: 'left' }}>
                                        <Grid item >
                                            <h6 style={{}}>Availability for Another Professional</h6>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid item container style={{ display: 'flex' }}>
                                            {anyProfessionalDateAndTimeData?.data?.availableTimes?.map(timeSlot => (
                                                <TimeSlotButton available={timeSlot.available} timeSlot={timeSlot} handleTimeClick={handleTimeClick} key={timeSlot.startTimeText} isAnyProfessional={true} />
                                            )) || null}
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                null
                        }
                    </Box>
                    <Box style={{ height: 100, width: '100%' }}>

                    </Box>
                </div>
            </Box>
        </>
    )
}



function TimeSlotButton({ timeSlot, handleTimeClick, available, isAnyProfessional }) {
    const activeBorderColor = available ? '#999999' : null
    const activeColor = available ? 'black' : null
    if (!available) {
        return null
    }
    return (
        <Grid item style={{ margin: 2, marginTop: 4 }} onClick={() => { handleTimeClick(timeSlot, isAnyProfessional) }}>
            <Button
                style={{ width: 95, height: 40, fontSize: 13, fontWeight: '600', borderWidth: 1, borderRadius: 10, borderColor: activeBorderColor, color: activeColor }}
                disabled={!available}
                variant='outlined'>
                {timeSlot.startTimeText}
                {/* {timeSlot.barber.firstName} */}
            </Button>
        </Grid>
    )
}

function MainDateMessage({message}) {
    if(!message) return null
    return (
        <div style={{backgroundColor:colors.primaryBlue25, border:`2px solid ${colors.primaryBlue}`, borderRadius:8, padding:20}}>
            <StandardCenterCenter>
            {message}
            </StandardCenterCenter>
        </div>
    )
}


function Closed() {
    return (
        <div>
            <img src="/assets/closed.png" width={160} height={160} />
        </div>
    )
}

