import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Avatar, Divider, ListItemAvatar } from '@mui/material'
import UserIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import DateIcon from '@mui/icons-material/CalendarToday'
import axios from 'axios'
import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonCol,
  IonLabel,
  IonRouterLink,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import Invoice from '../sharedComponents/invoice'
import { AuthContext } from '../auth'
import NoFutureApptsCard from '../commonComponents/NoFutureApptsCard'

const styles = {
  listItem: {
    paddingLeft: 0,
  },
}

export default function Review({
  userSubscription,
  scheduleData,
  updateScheduleData,
  stepNext,
  isWalkin,
  step,
  trackStep,
}) {
  const { userData, getUserData, locationData, getLocation, businessData } = useContext(AuthContext)
  const locationCancelationSettings = locationData?.appointmentSettings?.cancellationSettings

  let [errorMessage, setErrorMessage] = useState('')
  let [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)
  const [userNotificationsEnabled, setUserNotificationsEnabled] = useState(false)
  const [apptCount, setApptCount] = useState(0)
  const [cardOnFile, setCardOnFile] = useState(false)
  console.log(businessData, 'businessDatabusinessData')
  const maxLocationAppts = locationData?.appointmentSettings?.futureAppointmentsLimit

  let userCreatedEpochTime = ''
  let isCreatedMoreThanOneHrAgo = false
  if (userData?.createdDate) {
    userCreatedEpochTime = userData?.createdDate
    const oneHourInMilliseconds = 60 * 60 * 1000 // 1 hour in milliseconds

    const currentEpochTime = Date.now()

    //checkingif the account was create more than one hr ago
    isCreatedMoreThanOneHrAgo = userCreatedEpochTime < currentEpochTime - oneHourInMilliseconds
  }
  const displaySettings = !userNotificationsEnabled && isCreatedMoreThanOneHrAgo
  const getCustomerAppointmentCount = async () => {
    try {
      const response = await axios.get(`appointment/count?customerId=${userData?._id}`)
      setApptCount(response?.data?.count || 0)
    } catch (error) {}
  }
  const getCard = async () => {
    let response = await axios.get(`/customer/cardStripe`)
    setCardOnFile(response?.data?.cardOnFile || false)
  }
  useEffect(() => {
    trackStep('ReviewOrder', step, scheduleData)
    getLocation()

    if (userData) {
      getCard()
      getCustomerAppointmentCount()
    }
  }, [])
  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled)
  }

  const updateNotificationSettings = () => {
    if (userData?.notificationSettings?.textNotificationsEnabled) {
      setUserNotificationsEnabled(true)
    }
  }
  useEffect(() => {
    updateNotificationSettings()
  }, [userData])
  const updateUserNotifications = async () => {
    let selectedUserData = { ...userData }
    const notificationSettings = {
      ...selectedUserData.notificationSettings,
      textNotificationsEnabled: true,
      emailNotificationsEnabled: true,
    }
    selectedUserData['notificationSettings'] = notificationSettings

    try {
      let response = await axios.patch(`/customer?id=${userData?._id}`, selectedUserData)
      if (response.status == 200) {
        getUserData()
      }
    } catch (error) {}
  }
  async function handleBookAndPay() {
    if (displaySettings) {
      updateUserNotifications()
    }
    setErrorMessage('')
    setSubmitButtonDisabled(true)
    const source = sessionStorage.getItem('refid') // Retrieve the source from session storage
    let appointmentInput = {
      businessId: scheduleData.businessId,
      locationId: scheduleData.location?._id,
      customerId: scheduleData.customer?._id,
      barberId: scheduleData.barber?._id,
      requestedBarberId: scheduleData?.requestedBarber?._id,
      anyProfessional: scheduleData?.anyProfessional,
      services: scheduleData.services,
      selectedDate: scheduleData.dateTime?.dateText,
      startTime: scheduleData.dateTime?.startTime,
      endTime: scheduleData.dateTime?.endTime,
      startTimeText: scheduleData.dateTime?.startTimeText,
      endTimeText: scheduleData.dateTime?.endTimeText,
      durationMin: scheduleData?.durationMin,
      payment: scheduleData?.payment,
      type: isWalkin ? 'Walkin' : 'Appointment',
    }
    if (source) {
      appointmentInput.source = source
    }
    try {
      let response = await axios.post(`/appointment`, appointmentInput)

      if (response.status == 200) {
        stepNext()
      } else {
        setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      }
      setSubmitButtonDisabled(false)
    } catch (error) {
      setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      setSubmitButtonDisabled(false)
    }
  }

  function handleTipClick(value) {
    updateScheduleData('payment', { ...scheduleData.payment, ...{ tipPercentage: value } })
  }

  return (
    <React.Fragment>
      {/* <HeaderComponent title={'Review and Pay'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
      <div style={{ marginLeft: 14, marginRight: 14 }}>
        {isWalkin ? (
          <>
            <h2>Reserve Your Spot In Line</h2>
            <br></br>
          </>
        ) : (
          <>
            {/* <h5>Details</h5> */}
            {/* <LocationSummary location={scheduleData && scheduleData.location || null} /> */}
            <br></br>
            <BarberSummary
              barber={(scheduleData && scheduleData.barber) || null}
              anyProfessional={scheduleData?.anyProfessional}
            />
            <DateAndTimeSummary
              durationMin={scheduleData?.durationMin}
              dateTime={scheduleData?.dateTime || null}
              services={scheduleData?.services}
            />
          </>
        )}
        {/* {isWalkin ? null :
          <>
            <h5>Payment Method</h5>
            {cardOnFile && cardOnFile != 'loading' ? null :  <p style={{fontSize:14, marginTop:-4}}>Setting up a payment method now makes checkout smoother later on. Don't worry, we'll only charge you after your appointment is over.</p> }
            <Divider />

            <CardOnFile setIsCardOnFile={setCardOnFile} buttonText='Add Payment Method' />
          </>
        } */}
        <Divider />
        <br></br>
        <h5>Invoice</h5>
        <Invoice appointment={scheduleData} hideHeader={true} />
        <br></br>
        <Typography style={{ fontSize: 12, color: 'red' }}>{errorMessage}</Typography>
        {/* {cardOnFile && cardOnFile != 'loading' || isWalkin ? */}

        <>
          {displaySettings && (
            <IonRow onClick={toggleNotifications}>
              <IonCol size='auto'>
                <IonCheckbox
                  mode='ios'
                  checked={notificationsEnabled}
                  onIonChange={toggleNotifications}
                  labelPlacement='end'
                />
              </IonCol>
              <IonCol>
                <IonLabel style={{ textAlign: 'left', fontSize: 12 }}>
                  I agree to receive texts & emails reminding me about my appointment and marketing
                  messages. Data rates may apply. Go to notification settings in profile to opt out.
                </IonLabel>
              </IonCol>
            </IonRow>
          )}
          {apptCount >= maxLocationAppts ? (
            <NoFutureApptsCard apptCount={apptCount} />
          ) : (
            <>
              {cardOnFile && locationCancelationSettings?.charge && (
                <p style={{ color: 'grey', fontSize: '0.7rem' }}>
                  By booking this appointment I acknowledge that if I cancel an appointment within
                  24 hours of the appointment there will be a late fee charged. See{' '}
                  <IonRouterLink href={businessData?.termsAndConditionsLink} target='_blank'>
                    terms and conditions
                  </IonRouterLink>{' '}
                  for more details
                </p>
              )}
              <IonButton disabled={submitButtonDisabled} onClick={handleBookAndPay} expand='block'>
                {submitButtonDisabled ? (
                  <IonSpinner name='dots'></IonSpinner>
                ) : (
                  `${
                    isWalkin
                      ? 'Check In Now'
                      : userSubscription?.status == 'active'
                      ? 'Book Now'
                      : 'Book Now'
                  }`
                )}
              </IonButton>
            </>
          )}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </>
        {/* :
          <>
            {cardOnFile == 'loading' ?
              null
              :
              <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', padding: 20, backgroundColor: '#1af9286e', borderRadius: 10 }}>
                <p>Please add a payment method to book your appointment. We do not charge you until after your appointment is complete.</p>
              </div>
            }

          </>
        } */}
      </div>
    </React.Fragment>
  )
}

function BarberSummary({ barber, anyProfessional }) {
  if (!barber) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -10, marginBottom: -10 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('barber')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <UserIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          // primary={`${barber?.firstName} ${barber?.lastName?.charAt(0)}`}
          primary={
            anyProfessional
              ? 'Any Professional'
              : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`
          }
        />
      </ListItem>
    </List>
  )
}

function LocationSummary({ location, setStep }) {
  if (!location) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -20, marginBottom: -10 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('location')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <PlaceIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${location.name}`}
          secondary={`${location.addressStreet1}, ${location.addressCity}, ${location.addressState} ${location.addressZipcode}`}
        />
      </ListItem>
    </List>
  )
}

function DateAndTimeSummary({ dateTime, services, durationMin }) {
  if (!dateTime) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -20, marginBottom: 0 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('dateTime')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <DateIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${dateTime.startTimeText}, ${dateTime.dateText}`}
          secondary={`${durationMin} Minutes`}
        />
      </ListItem>
    </List>
  )
}
