import { IonLabel } from '@ionic/react'
import ConversationPlaceholder from './ConversationPlaceholder'
import { timeSince } from '../utils'
import { useEffect } from 'react'
import axios from 'axios'

export default function Conversation({ messages, loadingConversation, placeholderCount }) {
  const userUnreadMessages = messages
    ? messages.filter((item, index) => item.status == 'unread' && item.authorType === 'User')
    : []
  const customerUnreadMessages = messages
    ? messages.filter((item, index) => item.status == 'unread' && item.authorType === 'Customer')
    : []
  const readMessages = messages ? messages.filter((item, index) => item.status == 'read') : []

  const updateMessageStatus = async messageId => {
    try {
      const response = await axios.patch(`/conversation/message?id=${messageId}`, {
        status: 'read',
      })
      console.log(response.data)
      return response.data // Return the response data if needed
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update message status')
    }
  }

  useEffect(() => {
    // Update status for all unread messages when component mounts
    if (userUnreadMessages && userUnreadMessages.length > 0) {
      userUnreadMessages.forEach(async message => {
        await updateMessageStatus(message._id) // Assuming 'id' is the unique identifier of the message
      })
    }
  }, [userUnreadMessages]) // Trigger this effect whenever userUnreadMessages array changes
  const hasUnreadCustomerMessages = userUnreadMessages.some(
    message => message.status === 'unread' && message.authorType === 'User',
  )

  return (
    <>
      {loadingConversation ? <ConversationPlaceholder count={placeholderCount} /> : null}
      {!loadingConversation && (
        <>
          {readMessages?.map((message, index) => {
            return (
              <div class={`message-list ${message.authorType === 'Customer' ? 'me' : ''}`}>
                <div class={`msg ${message.status == 'unread' ? 'unread' : 'read'}`}>
                  <IonLabel>{message.body}</IonLabel>
                </div>
                {index == messages.length - 1 && (
                  <div class='time'>{timeSince(new Date(message.createdTimestamp))}</div>
                )}
              </div>
            )
          })}
          {hasUnreadCustomerMessages && (
            <div className='divider'>
              <div className='line'></div>
              <IonLabel className='divider-text'>New Messages</IonLabel>
              <div className='line'></div>
            </div>
          )}

          {/* Display "New Messages" for employee type if there are unread employee messages */}

          {userUnreadMessages?.map((message, index) => {
            return (
              <div class={`message-list ${message.authorType === 'Customer' ? 'me' : ''}`}>
                <div class={`msg ${message.status == 'unread' ? 'unread' : 'read'}`}>
                  <IonLabel>{message.body}</IonLabel>
                </div>
                {index == messages.length - 1 && (
                  <div class='time'>{timeSince(new Date(message.createdTimestamp))}</div>
                )}
              </div>
            )
          })}
          {customerUnreadMessages?.map((message, index) => {
            return (
              <div class={`message-list ${message.authorType === 'Customer' ? 'me' : ''}`}>
                <div class={`msg ${message.status == 'unread' ? 'unread' : 'read'}`}>
                  <IonLabel>{message.body}</IonLabel>
                </div>
                {index == messages.length - 1 && (
                  <div class='time'>{timeSince(new Date(message.createdTimestamp))}</div>
                )}
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
