import { IonButtons, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import axios from "axios";
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { personCircle } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonAlert } from '@ionic/react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { trackError } from './analytics';

function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}



const Login = ({ cancel, switchView, removeHeader, headerMessage, complete }) => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const handleCancel = () => {
    cancel()
  }

  const handleLogin = async () => {
    setSending(true)
    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      setSending(false)
      return;
    }
    if (validateEmail(email) === false) {
      setMessage("Your email is invalid");
      setIserror(true);
      setSending(false)
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Please enter your password");
      setIserror(true);
      setSending(false)
      return;
    }

    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
      setSending(false)
      complete()
    } catch (error) {
      if(error.code === "auth/user-not-found"){
        setMessage("Email not found. Please try another email or create an account.");
      } else if(error.code === "auth/wrong-password"){
        setMessage("Incorrect Password.");
      } else {
        setMessage(error.message);
      }

      trackError("Auth", error.message, error.code)
      
      setSending(false)
      setIserror(true)
    }

  }

  return (
    <>
      {removeHeader ? null :
        <IonHeader>
          <IonToolbar color="#fffffe">
            <IonTitle>Login</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleCancel}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>}
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>

              <h2 style={{marginTop:-20}}>{headerMessage}</h2>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Oops!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{ fontSize: "70px", color: "#0040ff" }}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating"> Email</IonLabel>
                <IonInput
                  name='email'
                  type="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating"> Password</IonLabel>
                <IonInput
                  name='password'
                  type="password"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p style={{ fontSize: "small" }}>
                By clicking LOGIN you agree to our <a href="https://www.clubcuts.com/terms-and-conditions">Policy</a>
              </p>
              <IonButton expand="block" disabled={sending} onClick={handleLogin}>{sending ? <IonSpinner name='dots'/> : "Login"}</IonButton>
              <p style={{ fontSize: "medium" }}>
                Don't have an account? <a onClick={() => { switchView() }}>Sign up!</a>
              </p>
              <p style={{ fontSize: "medium" }}>
                Forgot your password? <a onClick={() => { switchView('passwordReset') }}>Password Reset</a>
              </p>

            </IonCol>
          </IonRow>
        </IonGrid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </IonContent>
    </>
  );
};

export default Login;