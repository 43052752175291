export function calculatePayment(services = [], products = [], subscriptionPackageId, tipAmount = 0, tipId, taxRate = 0.05) {
    let payment = {
        tipAmount,
        tipId,
        taxes: 0,
        taxRate,
        productsTotal: 0,
        servicesTotal: 0,
        otherDiscounts: 0,
        subscriptionDiscount: 0,
        discountsTotal: 0,
        totalPrice: 0,
        discounts: []
    }

    //Services Total & Subscription Discounts
    for (let s of services) {
        payment.servicesTotal += (parseFloat(s.price))
        if (subscriptionPackageId && s?.includedInSubscriptions?.some(subscription => subscription._id === subscriptionPackageId)) {
            payment.discounts.push({ name: "Subscriber Discount - " + s.name, amount: (parseFloat(s.price)) })
            payment.subscriptionDiscount += (parseFloat(s.price))
        }
    }

    //Products
    for (let p of products) {
        payment.productsTotal += (parseFloat(p.price))
        payment.taxes += (parseFloat(p.price) * 0.05)
        if (subscriptionPackageId) {
            payment.discounts.push({ name: "Subscriber Discount (15%) - " + p.name, amount: (parseFloat(p.price) * 0.15) })
            payment.subscriptionDiscount += (parseFloat(p.price) * 0.15)
        }

    }

    payment.discountsTotal = payment.subscriptionDiscount + payment.otherDiscounts

    payment.totalPrice =
        payment.servicesTotal +
        payment.tipAmount +
        payment.taxes +
        payment.productsTotal -
        payment.discountsTotal

    return payment
}
