import { IonButton, IonIcon } from '@ionic/react';
import { checkmark, checkmarkCircleOutline } from 'ionicons/icons';

const SuccessComponent = ({title, subTitle, buttonLink, buttonText}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems:'center', width:'100%' }}>
      <div 
        style={{ 
          width: '100px', 
          height: '100px', 
          borderRadius: '50%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 255, 0, 0.1)', 
        }}
      >
        <div 
          style={{ 
            width: '70px', 
            height: '70px', 
            borderRadius: '50%', 
            backgroundColor: 'rgba(0, 255, 0, 1)', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
          }}
        >
          <IonIcon icon={checkmark} style={{ fontSize: '2.5rem', color: 'white' }} />
        </div>
      </div>

      <h2 style={{fontFamily:'Reenie Beanie', fontWeight:'700', fontSize:50, margin:0}}>{title}</h2>
      <p>{subTitle}</p>
      <IonButton routerDirection='back' routerLink={buttonLink} expand='block' style={{minWidth:200}}>{buttonText || " Done "}</IonButton>
    </div>
  );
};

export default SuccessComponent;
