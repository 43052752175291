import { IonCard } from '@ionic/react';
import {colors} from '../theme/colors'


export default function Card({ children, showBorder=false, onClick, routerLink, routerDirection }) {

    let theStyle ={  width: '100%', borderRadius:20 }
    if(showBorder){
        theStyle = {...theStyle, ...{border: `1px solid ${colors.primaryBlue}`}}
    }

    return (
        <IonCard mode='ios' style={theStyle} onClick={onClick} routerLink={routerLink} routerDirection={routerDirection}>
            {children}
        </IonCard>
    );
};


