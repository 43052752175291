import { IonButton, IonCol, IonContent, IonInput, IonPage, IonRow, IonSpinner } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import axios from 'axios'
import Conversation from './Conversation'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router'

export default function ConversationDetails({ match }) {
  const [sendingMessage, setSendingMessage] = useState(false)
  const twilioConversationId = match?.params?.id
  const [conversation, setConversation] = useState(false)
  const [loadingConversation, setLoadingConversation] = useState(false)
  const pageSize = 20
  const [pageNumber, setPageNumber] = useState(1)
  const [draft, setDraft] = useState('')
  const { locationData, businessData, userData } = useContext(AuthContext)
  const [messages, setMessages] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [totalMessages, setTotalMessages] = useState(0)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const getConversationMessages = async (twilioConversationId, page, showLoader) => {
    if (showLoader) {
      setLoadingConversation(true)
    } else {
      setLoadingMessages(true)
    }
    try {
      const conversationResponse = await axios.get(
        `/conversation/message?twilioConversationId=${twilioConversationId}&page=${page}&pageSize=${pageSize}`,
      )
      if (conversationResponse.status == 200) {
        setTotalMessages(conversationResponse?.data?.total)
        if (showLoader) {
          setLoadingConversation(false)
        } else {
          setLoadingMessages(false)
        }
        const messagesSortedBasedOnCreatedTime = conversationResponse?.data?.messages.sort(
          (a, b) => a.createdTimestamp - b.createdTimestamp,
        )
        const sortedExistingMessages = messages.sort(
          (a, b) => a.createdTimestamp - b.createdTimestamp,
        )
        const combinedMessages = messagesSortedBasedOnCreatedTime.concat(sortedExistingMessages)
        setMessages(page == 1 ? messagesSortedBasedOnCreatedTime : combinedMessages)
        setConversation(conversationResponse?.data)
        setPage(page + 1)
      }
    } catch (error) {}
  }
  // Function to handle input changes
  const handleInputChange = event => {
    setDraft(event.detail.value)
  }
  const updateMessageStatus = async messageId => {
    try {
      const response = await axios.patch(`/conversation/message?id=${messageId}`, {
        status: 'read',
      })
      console.log(response.data)
      return response.data // Return the response data if needed
    } catch (error) {
      console.error(error)
      throw new Error('Failed to update message status')
    }
  }
  // Function to send a message
  const sendMessage = async () => {
    console.log(conversation, 'conversationconversation')
    if (draft.trim() !== '') {
      setSendingMessage(true)
      try {
        const messageInput = {
          locationId: locationData?._id,
          businessId: businessData?._id,
          userId: userData?._id,
          customerId: userData?._id,
          twilioConversationId: twilioConversationId,
          conversationId: conversation.conversationId,
          body: draft,
          author: userData?._id,
          authorType: 'Customer',
        }

        const response = await axios.post('/conversation/message', messageInput)
        if (response.status == 200) {
          const unreadMessages = messages
            ? messages.filter(
                (item, index) => item.status == 'unread' && item.authorType === 'User',
              )
            : []
          if (unreadMessages && unreadMessages.length > 0) {
            unreadMessages.forEach(async message => {
              await updateMessageStatus(message._id) // Assuming 'id' is the unique identifier of the message
            })
          }
          setSendingMessage(false)
          getConversationMessages(twilioConversationId, 1, false)
        }
      } catch (error) {}

      setDraft('')
    }
  }
  const handleScroll = async event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target

    // Store the current scroll position
    const currentScrollPosition = scrollTop

    if (scrollTop == 0 && !loading && messages.length < totalMessages) {
      await getConversationMessages(twilioConversationId, page, false)

      // Set the scroll position back to its previous value after loading new messages
      // event.target.scrollTop = currentScrollPosition
    }
  }
  useEffect(() => {
    getConversationMessages(twilioConversationId, 1, true)
  }, [twilioConversationId])
  console.log(conversation, 'conversationconversation')
  const title = !loadingConversation ? conversation?.userEmail : ''
  return (
    <IonPage id='main-content'>
      <CommonHeader title={title} backIcon={true}></CommonHeader>
      <IonContent className='regularBackground'>
        <div className='container'>
          <div className='content'>
            <div
              class='message-wrap'
              onScroll={handleScroll}
              style={{ padding: '1rem', height: '100%' }}
            >
              {loadingMessages && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonSpinner name='dots' />
                </div>
              )}
              <Conversation
                mobileView={true}
                placeholderCount={7}
                loadingConversation={loadingConversation}
                messages={messages}
              />
            </div>
            <div class='message-footer '>
              <IonRow>
                <IonCol size='9'>
                  <IonInput
                    style={{ margin: '5px' }}
                    disabled={sendingMessage}
                    value={draft}
                    placeholder='Type a message...'
                    onIonChange={handleInputChange}
                  />
                </IonCol>
                <IonCol size='3'>
                  <IonButton
                    color={'dark'}
                    disabled={!draft || sendingMessage}
                    expand='block'
                    onClick={sendMessage}
                  >
                    {sendingMessage && (
                      <div>
                        <IonSpinner name='lines' style={{ marginRight: '10px' }} />
                      </div>
                    )}
                    Send
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
