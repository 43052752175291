import React from 'react';
import { Typography } from '@mui/material';


export default function StatusPill({status}) {
  let Lookup = {
    'Scheduled' : {color:"#0084edd1", width:110},
    'Canceled' : {color:"#ff5722d9", width:110},
    'Complete' : {color:"#00c669", width:110},
    
  }

  let options = Lookup[status] || {color:'grey'}

  return (
    <div style={{ height: 20, borderRadius: 10, backgroundColor: options.color, width: options.width, alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center', fontSize: 13, fontWeight: '700', color: 'white' }}>
      {status}
    </div>
  )
}