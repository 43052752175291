import React, { useEffect, useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons, IonImg } from '@ionic/react'
import { RouteComponentProps } from 'react-router';
import axios from 'axios'


const LocationDetails = ({ match, history }) => {
  return (
    <IonPage>
      <IonHeader color={'transparent'}>
        <IonToolbar color={'transparent'}>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Location</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <BodyContent id={match.params.id}/>
      </IonContent>
    </IonPage>
  );
};

const BodyContent = ({ id }) => {
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getData()
  }, [id])

  async function getData() {
    try {
      setError(false)
      let d = await axios.get(`/location/publicGet?locationId=${id}`)
      setData(d.data)
      setLoading(false)
    } catch (error) {
      setError(true)
    }

  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>error</div>


  return (
    <>
      <div style={{ position: 'relative' }}>
        <IonImg style={{ width: '100%', height: '100%', backgroundSize: 'cover' }} src={"https://ionicframework.com/docs/img/demos/card-media.png"} ></IonImg>
        <div style={{ backgroundColor: 'transparent', backgroundImage: "linear-gradient(transparent, rgba(0,0,0,1))", width: '100%', height: 90, position: 'absolute', zIndex: 99, bottom: 0, color: '#fff', paddingLeft: 20, textShadow: '0px 0px 20px #000' }}>
          <h1 style={{ fontWeight: '700', position: 'absolute', bottom: 0 }}>{data.name} 222</h1>
        </div>
      </div>
      Location {id}
    </>
  );
};


export default LocationDetails;