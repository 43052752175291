import { IonCard, IonCol, IonGrid, IonIcon, IonRouterLink, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { colors } from "../theme/colors";
import { checkmarkCircleOutline } from "ionicons/icons";
import StandardContainer from "../commonComponents/standardContainer";
import StandardCenterCenter from "../commonComponents/StandardCenterContainer";



export default function Testimonials() {


  const [size, setSize] = useState(window.innerWidth <= 768 ? 'small' : 'large');

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth <= 768 ? 'small' : 'large');
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>



      <div style={{ backgroundColor: 'white' }}>
        <StandardContainer>
          <div style={{ color: 'black', paddingTop: 30 }}>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, lineHeight: 0.9, textAlign: (size == 'small' ? "left" : 'center') }}>WHAT DO PEOPLE SAY ABOUT US<span style={{ color: colors.gold }}>?</span></h1>
            <h6 style={{ textAlign: (size == 'small' ? "left" : 'center') }}>Don't take it from us. Hear why people love ClubCuts!</h6>
          </div>
          <PersonCard
            name={"Ethan Sizemore"}
            quote={"Needed a haircut and got an appointment same day, got the best haircut I’ve ever gotten, my stylist was so considerate and incredibly skilled!"}
            headline={"The best haircut I’ve ever gotten"}
          />
          <PersonCard
            name={"Ryan Lancaster"}
            quote={"I had great experience here the crew working was very friendly and motivated with there work 10/10 recommend"}
            headline={"The crew working was very friendly"}
          />
          <PersonCard
            name={"Hector Uriarte"}
            quote={"This is easily one of the best barbershop I’ve ever been to! Aaryn is a master at her craft and left my hair exactly how I wanted it. She even fixed some issues I had with hair growing the wrong way. Booking is super easy online, pricing is fair, and the service is excellent!!"}
            headline={"The best barbershop I’ve ever been to"}
          />
          <br></br>

          <h6 style={{ textAlign: (size == 'small' ? "left" : 'center'), color: colors.black }}>There's a lot more where that came from!</h6>
          <StandardCenterCenter>
            <IonRouterLink routerLink='/schedule' routerDirection='none'>
              <div style={{ fontFamily: 'Montserrat, Arial', padding: 10, fontWeight: '600', fontSize: 16, color: 'black', backgroundColor: colors.gold, border: `1px solid ${colors.gold}`, borderRadius: 6, textAlign: 'center', marginTop: 20, width: 300 }}>
                Book Now
              </div>
            </IonRouterLink>
          </StandardCenterCenter>
          <br></br>
        </StandardContainer>
      </div>

    </>
  );
}

function PersonCard({ name, quote, headline }) {
  return (

    <IonCard style={{ color: 'black', padding: 20, margin: 0, marginTop: 20 }}>
      <h4>"{headline}"</h4>
      <h6>{name}</h6>
      <div style={{ marginTop: -8, marginBottom: 4, color: '#eeb544', fontSize: 16 }}>
        ★★★★★
      </div>
      <p style={{ fontSize: 16 }}>{quote}</p>

      <IonGrid style={{ marginTop: -16, marginBottom: -10 }}>
        <IonRow>
          <IonCol></IonCol>
          <IonCol size='auto'>
            <IonIcon icon={checkmarkCircleOutline} style={{ marginTop: 17, color: 'grey', marginRight: -6 }} />
          </IonCol>
          <IonCol style={{ minWidth: 120 }}>
            <h6 style={{ color: 'grey', fontSize: 14, marginBottom: -6 }}> Google Review</h6>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>

    </IonCard>
  )
}