import { IonCol, IonLabel, IonRow } from '@ionic/react'
import { colors } from '../theme/colors'
import { timeSince } from '../utils'

export default function ConversationsList({
  conversationList,
  handleCustomerClick,
  selectedConversation,
}) {
  return (
    <sidebar>
      <div class='list-wrap'>
        {conversationList?.map(conversation => {
          return (
            <div
              key={conversation.id}
              onClick={() => handleCustomerClick(conversation)}
              class='list'
            >
              <IonLabel
                style={{
                  padding: '10px',
                  backgroundColor: colors.lightGrey,
                  borderRadius: '50%',
                }}
              >
                {conversation.userEmail[0]}
              </IonLabel>
              <div class='info'>
                <IonLabel class='user'>{conversation.userEmail}</IonLabel>
                <IonLabel class='text latest-message'> {conversation?.latestMessageBody}</IonLabel>
              </div>
              {conversation.unReadMessagesInfo ? (
                <IonLabel
                  class='time'
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primaryBlue,
                    height: '1.5rem',
                    width: '1.5rem',
                    borderRadius: '50%',
                  }}
                >
                  {conversation.unReadMessagesInfo}
                </IonLabel>
              ) : null}
              <IonLabel class='time'>
                {timeSince(
                  new Date(
                    conversation?.latestMessageCreatedTimestamp || conversation?.createdTimestamp,
                  ),
                )}
              </IonLabel>
            </div>
          )
        })}
      </div>
    </sidebar>
  )
}
