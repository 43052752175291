import {  IonCol, IonGrid, IonRow } from '@ionic/react';
import MiniHeader from './MiniHeader';

export default function Invoice({ appointment, hideHeader }) {
    return (
        <>
            {hideHeader ? null : <MiniHeader title='Invoice' />}
            <br></br>
            <IonGrid style={{ padding: 0, margin: -5 }}>
                { appointment?.services?.map((s) => <RowItem title={s.name} value={`$${parseFloat(s.price)?.toFixed(2)}`}/>)}
                { appointment?.products?.map((s) => <RowItem title={s.name} value={`$${parseFloat(s.price)?.toFixed(2)}`}/>)}
                { appointment?.payment?.discounts?.map((d) =><RowItem title={d.name} value={`-$${d?.amount?.toFixed(2)}`}/>)}
                { appointment?.payment?.taxes !== 0 || ['Complete'].includes(appointment?.status) ? <RowItem title={"Taxes"} value={`$${appointment?.payment?.taxes?.toFixed(2)}`}/> : null}
                {['Complete'].includes(appointment?.status) ?
                    <RowItem title="Tip" value={`$${appointment?.payment?.tipAmount?.toFixed(2)}` || "$ -"}/>
                    : null}
                <IonRow>
                    <IonCol>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#cdcdcd' }}></div>
                    </IonCol>
                </IonRow>
                <RowItem title={"TOTAL"} value={`$${appointment?.payment?.totalPrice?.toFixed(2)}`} isBold={true}/>
            </IonGrid>
        </>
    );
}

function RowItem({title, value, isBold}) {
    let modifiedStyles = {}
    if(isBold){
        modifiedStyles ={ fontWeight: '700' }
    }
    return (
        <IonRow key={title} style={modifiedStyles}>
            <IonCol>{title || ""}</IonCol>
            <IonCol size='auto'>{value}</IonCol>
        </IonRow>
    )
}

