import { IonButton, IonCard, IonImg } from '@ionic/react';
import { colors } from '../theme/colors'
import Card from './card'


export default function CreateAccountCard({ firebaseUser, openLoginModal, openRegisterModal }) {


    if (firebaseUser == 'loading') return null

    if (firebaseUser) return null

    return (
        <>

            <div style={{ position: 'relative', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingLeft: 15, paddingRight: 15, marginBottom: -20, marginTop: -20 }}>
                <Card>

                    <IonImg style={{ width: '100%', height: 90, objectFit: 'cover', marginTop:15, marginBottom:15 }} src={'/assets/barberIcons.png'} ></IonImg>
                    {/* <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 600, height: '100%', position: 'absolute', zIndex: 99, top: 0, color: '#fff', marginTop: 20 }}>
                        <h1 style={{ fontFamily: 'Montserrat, Arial', margin: 30, marginTop: -0, fontWeight: '800', fontSize: 40 }}>JOIN THE CLUB</h1>
                    </div> */}
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color:'black' }}>
                        <h1 style={{ fontFamily: 'Montserrat, Arial', margin: 20, marginTop: 0, marginBottom:0, fontWeight: '800', fontSize: 36 }}>JOIN THE CLUB</h1>
                        <p style={{ fontFamily: 'Montserrat, Arial', margin: 20, marginTop: 10, marginBottom:40, fontWeight: '500', fontSize: 18 }}>Login or register for the full experience</p>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#e6e6e6' }} />
                        <IonButton fill='clear' style={{ fontFamily: 'Montserrat', zIndex: 1000, color: 'black', margin: 0, fontWeight: '600' }} onClick={openRegisterModal}>Create Account</IonButton>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#e6e6e6' }} />
                        <IonButton fill='clear' style={{ fontFamily: 'Montserrat', zIndex: 1000, color: 'black', margin: 0 }} onClick={openLoginModal}>Login</IonButton>
                    </div>
                </Card>
            </div>

        </>
    )
}

