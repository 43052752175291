import * as React from 'react';
import FunAvatar from './funAvatar'
import B64Image from './b64Image'



export default function Avatar({employee, businessId, small, mini, medium, cacheBuster}) {
  let height = 150
  if(medium) height = 50
  if(small) height = 40
  if(mini) height = 25
  
  return <B64Image cacheBuster={cacheBuster} height={height} width={height} url={`/employee/profilePic?employeeId=${employee._id}&businessId=${businessId}`}/>
}
