import {
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { personCircle } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonAlert } from '@ionic/react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from './auth'
import PhoneNumberTextField from './commonComponents/phoneNumberTextField'
import { trackCreatedAccount, trackError } from './analytics'

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/
  return re.test(String(email).toLowerCase())
}
const Register = ({ cancel, switchView, removeHeader, headerMessage, complete }) => {
  const history = useHistory()
  const { getUserData } = useContext(AuthContext)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [password, setPassword] = useState()
  const [iserror, setIserror] = useState(false)
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)
  const handleCancel = () => {
    cancel()
  }
  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled)
  }

  const handleLogin = async () => {
    setSending(true)
    if (!email) {
      setMessage('Please enter a valid email')
      setIserror(true)
      setSending(false)
      return
    }
    if (!firstName || firstName == '') {
      setMessage('Your first name is missing')
      setIserror(true)
      setSending(false)
      return
    }
    if (!lastName || lastName == '') {
      setMessage('Your last name is missing')
      setIserror(true)
      setSending(false)
      return
    }
    if (validateEmail(email) === false) {
      setMessage('Your email is invalid')
      setIserror(true)
      setSending(false)
      return
    }

    if (!phoneNumber || phoneNumber == '') {
      setMessage('Your Phone Number is Missing')
      setIserror(true)
      setSending(false)
      return
    }

    if (!password || password.length < 6) {
      setMessage('Please enter a longer password')
      setIserror(true)
      setSending(false)
      return
    }

    let businessId = process.env.REACT_APP_BUSINESS_ID
    if (!businessId) {
      setMessage('Critical Error')
      setIserror(true)
      setSending(false)
    }

    try {
      let response = await createUserWithEmailAndPassword(getAuth(), email, password)
      const source = sessionStorage.getItem('refid') // Retrieve the source from session storage
      let customerInput = {
        firstName,
        lastName,
        email,
        phoneNumber,
        firebaseId: response.user.uid,
        businessId,
        notificationSettings: {
          textNotificationsEnabled: notificationsEnabled,
          emailNotificationsEnabled: notificationsEnabled,
        },
      }
      if (source) {
        customerInput.source = source
      }
      await axios.post('/customer', customerInput)
      getUserData()
      setSending(false)
      trackCreatedAccount()
      complete()
    } catch (error) {
      console.log(error)
      if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
        setMessage('This email is already in use')
      } else {
        setMessage(`Registration failure! Please try again soon: ${error?.code || ''}`)
      }
      trackError('Register', error?.message, error?.code)
      setIserror(true)
      setSending(false)
    }
  }

  return (
    <>
      {removeHeader ? null : (
        <IonHeader>
          <IonToolbar color='white'>
            <IonTitle>Register</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={handleCancel}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen className='ion-padding ion-text-center'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h2 style={{ marginTop: -20 }}>{headerMessage}</h2>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass='my-custom-class'
                header={'Error!'}
                message={message}
                buttons={['Dismiss']}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon style={{ fontSize: '70px', color: '#0040ff' }} icon={personCircle} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'> First Name</IonLabel>
                <IonInput
                  type='text'
                  value={firstName}
                  onIonChange={e => setFirstName(e.detail.value)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'> Last Name</IonLabel>
                <IonInput
                  type='text'
                  value={lastName}
                  onIonChange={e => setLastName(e.detail.value)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'> Phone Number</IonLabel>
                <PhoneNumberTextField
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
                {/* <IonInput
                  type="phone"
                  value={phoneNumber}
                  onIonChange={(e) => setPhoneNumber(e.detail.value)}
                >
                </IonInput> */}
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'> Email</IonLabel>
                <IonInput
                  type='email'
                  value={email}
                  onIonChange={e => setEmail(e.detail.value)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'> Password</IonLabel>
                <IonInput
                  type='password'
                  value={password}
                  onIonChange={e => setPassword(e.detail.value)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonGrid>
                <IonRow>
                  <IonCol size='auto'>
                    <IonCheckbox
                      mode='ios'
                      checked={notificationsEnabled}
                      onIonChange={toggleNotifications}
                      labelPlacement='end'
                    />
                  </IonCol>
                  <IonCol>
                    <div style={{ textAlign: 'left', fontSize: 11 }}>
                      I agree to receive texts & emails reminding me about my appointment and
                      marketing messages. Data rates may apply. Go to notification settings in
                      profile to opt out.
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p style={{ fontSize: 'small' }}>
                By clicking REGISTER you agree to our{' '}
                <a href='https://www.clubcuts.com/terms-and-conditions'>Policy</a>
              </p>
              <IonButton disabled={sending} expand='block' onClick={handleLogin}>
                {sending ? <IonSpinner name='dots' /> : 'Register'}
              </IonButton>
              <p style={{ fontSize: 'medium' }}>
                Already a Member?{' '}
                <a
                  onClick={() => {
                    switchView()
                  }}
                >
                  Login
                </a>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </IonContent>
    </>
  )
}

export default Register
