import { useContext, useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonImg,
  IonButton,
  IonAvatar,
  IonIcon,
  IonList,
  IonSpinner,
} from '@ionic/react'

import { AuthContext } from '../../auth'
import {
  addOutline,
  phonePortraitOutline,
  mailOutline,
  settingsOutline,
  pencil,
  chatboxEllipsesOutline,
} from 'ionicons/icons'
import Subscription from './subscription'
import CreateAccountCard from '../../commonComponents/createAccountCard'
import CardDetails from './cardDetails'
import AccountActionsModal from './accountActionsModal'
import EditUserPane from './EditUserPane'
import ManageNotifications from './ManageNotifications'
import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet'

const Account = ({ openLoginModal, openRegisterModal }) => {
  const { userData, firebaseUser, logout, getUserData } = useContext(AuthContext)
  const [accountActionsOpen, setAccountActionsOpen] = useState(false)
  const [editUserOpen, setEditUserOpen] = useState(false)
  const location = useLocation()
  const openEditUserModal = () => {
    setEditUserOpen(!editUserOpen)
  }
  const closeEditPane = () => {
    setEditUserOpen(false)
  }
  const closeEditPaneAndRefreshData = async () => {
    setEditUserOpen(false)
    await getUserData()
  }
  useEffect(() => {
    if (location.pathname == '/account') {
      getUserData()
    }
  }, [location])

  if (!firebaseUser || !userData) {
    return (
      <IonPage>
        {/* <Header title="Account" /> */}
        <Helmet>
          <title>ClubCuts | Account</title>
        </Helmet>
        <IonContent fullscreen={true} style={{ backgroundColor: 'white' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: 600, width: '100%' }}>
              <CreateAccountCard
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
              />
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }

  if (firebaseUser && (!userData || userData == 'loading')) {
    return (
      <IonPage>
        {/* <Header title="Account" /> */}
        <IonContent fullscreen={true}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 300,
              textAlign: 'center',
            }}
          >
            <IonSpinner name='dots' />
          </div>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      {/* <Header title="Account" /> */}
      <IonContent fullscreen={true} style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: 600, width: '100%' }}>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: '2rem 1rem',
                width: '100%',
              }}
            >
              <IonAvatar
                style={{ margin: 20, height: 100, width: 100, marginBottom: 0, marginTop: 50 }}
              >
                <img
                  alt="Silhouette of a person's head"
                  src='https://ionicframework.com/docs/img/demos/avatar.svg'
                />
              </IonAvatar>
              <IonLabel
                style={{
                  width: '100%',
                  fontSize: 30,
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  color: 'black',
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
                {}
                {userData?.firstName}&nbsp;{userData?.lastName}
              </IonLabel>
            </div>
            {editUserOpen && (
              <EditUserPane
                userData={userData}
                editUserOpen={editUserOpen}
                closeEditPaneAndRefreshData={closeEditPaneAndRefreshData}
                closeEditPane={closeEditPane}
              />
            )}

            <IonList inset={true} style={{ background: 'white' }} mode='ios'>
              <IonItem>
                <IonIcon icon={mailOutline} slot='start'></IonIcon>
                <IonLabel>{userData?.email}</IonLabel>
                <IonIcon
                  style={{ cursor: 'pointer' }}
                  color='primary'
                  onClick={openEditUserModal}
                  icon={pencil}
                  slot='end'
                ></IonIcon>
              </IonItem>
              <IonItem>
                <IonIcon icon={phonePortraitOutline} slot='start'></IonIcon>
                <IonLabel>{userData?.phoneNumber}</IonLabel>
              </IonItem>
            </IonList>
            <Subscription userId={userData?._id} />

            <CardDetails />

            <IonList inset={true} style={{ background: 'white' }} mode='ios'>
              <IonItem
                button={true}
                onClick={() => {
                  setAccountActionsOpen(true)
                }}
              >
                <IonIcon icon={settingsOutline} slot='start'></IonIcon>
                <IonLabel>Account Actions</IonLabel>
              </IonItem>
              <IonItem detail={true} routerLink='/messages'>
                <IonIcon icon={chatboxEllipsesOutline} slot='start'></IonIcon>
                <IonLabel>Message</IonLabel>
              </IonItem>
            </IonList>

            <ManageNotifications userData={userData} />
            <SignOut logout={logout} />
            <Version />
            <AccountActionsModal
              isOpen={accountActionsOpen}
              close={() => {
                setAccountActionsOpen(false)
              }}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

function HeroSection() {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <IonImg
        style={{
          width: '100%',
          maxWidth: 350,
          height: 250,
          marginLeft: -20,
          marginRight: -20,
          marginTop: -20,
        }}
        src={'/assets/accountbackground.png'}
      ></IonImg>
      <div
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          maxWidth: 600,
          height: '100%',
          position: 'absolute',
          zIndex: 99,
          top: 0,
          color: '#fff',
          marginTop: 20,
        }}
      ></div>
    </div>
  )
}

function CreditCard({ userData }) {
  if (userData.paymentMethods) {
    return (
      <div>
        <div
          style={{
            borderRadius: 4,
            padding: 20,
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          <h4 style={{ marginTop: 0 }}>Payment Methods</h4>
          <div>You have a credit card</div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        onClick={() => {
          alert('TODO')
        }}
        style={{ curson: 'pointer' }}
      >
        <div
          style={{
            borderRadius: 4,
            border: '1px dashed grey',
            padding: 20,
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <h4 style={{ marginTop: 0, position: 'relative' }}>Payment Methods</h4>
          <div
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                height: 60,
                width: 60,
                borderRadius: 30,
                border: '1px solid grey',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 20,
              }}
            >
              <IonIcon icon={addOutline} size='large' color='grey' style={{ color: 'grey' }} />
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            Please add a payment method. This is required for booking and appointment
          </div>
        </div>
      </div>
    )
  }
}

function SignOut({ logout }) {
  return (
    <div style={{ padding: 20 }}>
      <IonButton expand='block' fill='outline' onClick={logout}>
        Sign Out
      </IonButton>
    </div>
  )
}
function Version() {
  return (
    <div
      style={{ padding: 20, color: 'grey', fontSize: 12, fontWeight: '500', textAlign: 'center' }}
    >
      v {process.env.REACT_APP_VERSION}
    </div>
  )
}

export default Account
