import { IonCol, IonGrid, IonIcon, IonRouterLink, IonRow, IonSpinner } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
const SubscriptionInfoCard = ({ subscription }) => {
  return (
    <IonGrid style={{ margin: 0, marginLeft: -10, color: 'black' }}>
      <IonRow style={{ margin: 0 }}>
        <IonCol size='auto'>
          <div
            style={{
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 45,
              width: 45,
              background: 'linear-gradient(to top right, #FFD700, #FFDF00, #FFEA00)',
              borderRadius: 8,
            }}
          >
            <h1 style={{ margin: 0, marginTop: -4 }}>∞</h1>
          </div>
        </IonCol>
        <IonCol>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <h5 style={{ margin: 0, marginTop: 4 }}>{subscription?.subscription?.name} Package</h5>
            <h6 style={{ margin: 0, fontWeight: '600', fontSize: 14 }}>
              {subscription?.isCorporateDeal ? 'Corporate ' : null}Subscriber
            </h6>
            {subscription?.subscription?.cancellationDate ? (
              <h6 style={{ margin: 0, fontWeight: '600', fontSize: 14 }}>
                Cancellation Date : {subscription?.subscription?.cancellationDate}
              </h6>
            ) : null}

            {subscription?.isCorporateDeal ||
            subscription?.subscription?.cancellationDate ? null : (
              <h6
                style={{
                  margin: '10px  0',
                  fontWeight: '600',
                  fontSize: 14,
                  color: colors.primaryBlue,
                }}
              >
                Manage Subscription
              </h6>
            )}
          </div>
        </IonCol>
        {subscription?.isCorporateDeal || subscription?.subscription?.cancellationDate ? null : (
          <IonCol size='auto'>
            <div
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 45,
              }}
            >
              <IonIcon icon={chevronForwardOutline} style={{ fontSize: 21, color: '#bfbfbf' }} />
            </div>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  )
}

export default function SubscriberInfo({ subscription }) {
  return (
    <div style={{ padding: 0, margin: 0 }}>
      {subscription?.loading ? <IonSpinner name='dots' /> : null}

      {subscription?.data?.isCorporateDeal || subscription?.data?.subscription?.cancellationDate ? (
        <SubscriptionInfoCard subscription={subscription?.data} />
      ) : (
        <IonRouterLink routerLink={`/manageSubscription/${subscription?.data?.subscription?._id}`}>
          <SubscriptionInfoCard subscription={subscription?.data} />
        </IonRouterLink>
      )}
    </div>
  )
}
