import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonAvatar, IonImg, IonLabel, IonButton, IonIcon, IonGrid, IonRow, IonCol, IonRouterLink, IonCard } from '@ionic/react';
import Map from './map'
import Modal from './modal'
import { navigateOutline, chevronForwardOutline, cellular, phonePortraitOutline, call, calendarClearOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios'
import LocationDetails from './locationDetails'
import Header from '../../commonComponents/header';
import Card from '../../commonComponents/card';
import BookButton from '../../commonComponents/bookButton';
import StandardContainer from '../../commonComponents/standardContainer';
import { colors } from '../../theme/colors';
import {isLocationOpen} from '../../utils'

const MainPage = ({ openScheduleModal, businessId, locations }) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)

  return (
    <IonPage>
      {/* <Header title={'Location'} /> */}
      <IonContent fullscreen>
        <StandardContainer medium={true} padding={0}>
          <div style={{ position: 'fixed', top: 0, left: 0, height: 350, width: '100%' }}>
            <MapWrapper />
          </div>
          <div style={{ height: 400 }} />
          <HeroSection2 data={locations} />
        </StandardContainer>


        {/* <IonList>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton href={`tel:${data && data[0] && data[0].phoneNumber}`} expand='block'><IonIcon size='small' icon={call} /> &nbsp;&nbsp;Call</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton onClick={openScheduleModal} expand='block'><IonIcon size='small' icon={calendarClearOutline} /> &nbsp;&nbsp;Book</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonList> */}


      </IonContent>
    </IonPage>
  );
};

function MapWrapper() {
  return (
    <div style={{ marginBottom: -80 }}>
      <Map style={{ borderRadius: 5 }} />
    </div>
  )
}


function HoursOfOperation({ data }) {
  let schedule = data?.[0]?.standardSchedule

  if (!schedule) return null

  console.log(data)

  return (
    <IonGrid style={{ padding: 16, marginBottom:10,  color: 'black' }}>
      <IonRow>
        <IonCol style={{ textAlign: 'left' }}>
          <h6 style={{marginTop:0}}>Hours of Operation</h6>
          <p>  {isLocationOpen(data?.[0])}</p>
        </IonCol>
      </IonRow>
      {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((key) => (
        <IonRow key={key}>
          <IonCol></IonCol>
          <IonCol size='auto' style={{ textAlign: 'left' }}>
            <div style={{ minWidth: 130, fontWeight: '600' }}>{key}</div>
          </IonCol>
          <IonCol size='auto'>
            
            <div style={{ minWidth: 160, textAlign: 'left' }}>
            {
              schedule[key]?.enabled ? 
              <>{schedule[key]?.startTime} - {schedule[key]?.endTime}</>
              : "Closed"
            }
              
              
              </div>
          </IonCol>
          {/* <IonCol size='auto'>
            -
          </IonCol>
          <IonCol size='auto'>
            {schedule[key]?.startTime}
          </IonCol> */}
          <IonCol></IonCol>
        </IonRow>

      ))}
    </IonGrid>
  )
}

function HeroSection2({ data }) {

  async function handleCopy(d) {

    try {
      if (data && data.length > 0) {
        await copyTextToClipboard(`${d[0].addressStreet1} ${d[0].addressStreet2} ${d[0].addressCity}, ${d[0].addressState} ${d[0].addressZipcode}`);
        toastifySuccess();
        toast('Copied to Clipboard');
      } else {
        alert('data not there')
      }
    } catch (error) {
      console.log(error)
    }

  }



  const toastifySuccess = () => {
    console.log("toast")
    toast('Copied to Clipboard');
  };

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <>

      <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: -20, marginTop: -20 }}>
        <Card>

          {/* <IonImg style={{ width: '100%', height: 180, objectFit: 'cover' }} src={'/assets/hairCut.png'} ></IonImg> */}
          {/* <div style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', maxWidth: 600, height: '100%', position: 'absolute', zIndex: 99, top: 0, color: '#fff', height: 180 }}>
            <h1 style={{ fontFamily: 'Montserrat, Arial', margin: 20, fontWeight: '800', fontSize: 40 }}>{data && data[0] && data[0].name}</h1>
          </div> */}
          <div style={{ paddingTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <div style={{ height: 4, width: 40, borderRadius: 4, backgroundColor: '#e2e2e2' }}></div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left', padding: 20 }}>
            <IonLabel onClick={() => { handleCopy(data) }}>
              {data && data[0] ?
                <>
                  <h2 style={{ fontWeight: '600', color: 'black', paddingBottom: 0 }}>{data && data[0].name}</h2>
                  {data && data[0].name ?
                    <div style={{ marginTop: 0, marginBottom: 4, color: '#eeb544' }}>
                      ★★★★★
                    </div>
                    : null}
                  <p>{data && data[0].addressStreet1}</p>
                  {data && data[0].addressStreet2 ? <p>{data && data[0].addressStreet1}</p> : null}
                  <p>{data && data[0].addressCity}, {data && data[0].addressState} {data && data[0].addressZipcode}</p>
                  <p>{data && data[0].phoneNumber}</p>
                </>
                : null}
            </IonLabel>
            <IonRouterLink routerLink='/schedule' routerDirection='none' style={{ width: '100%' }}>
              <div style={{ marginTop: 20, fontFamily: 'Montserrat, Arial', padding: 10, fontWeight: '600', fontSize: 16, color: 'white', backgroundColor: 'black', border: `1px solid black`, borderRadius: 6, width: `100%`, textAlign: 'center', }}>
                Book Now
              </div>

              <IonCard style={{ margin: 0, marginTop: 20 }} >
                <IonImg style={{ width: '100%', height: 250, objectFit: 'cover', borderRadius: 20 }} src={'/assets/shopPic1.png'} />
              </IonCard>
            </IonRouterLink>
          </div>
          <HoursOfOperation data={data} />
        </Card>
      </div>

    </>
  )
}



export default MainPage;
