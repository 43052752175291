import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import StepController from '../Scheduler/stepController'
import { colors } from '../theme/colors'
import StandardContainer from '../commonComponents/standardContainer'
import { AuthContext } from '../auth'
import axios from 'axios'
import NoFutureApptsCard from '../commonComponents/NoFutureApptsCard'

export default function Schedule({ getAppointments, setSubscriptionReferrer }) {
  let [showStartPage, setShowStartPage] = useState(false)
  const { locationData, getLocation, userData } = useContext(AuthContext)
  const maxLocationAppts = locationData?.appointmentSettings?.futureAppointmentsLimit
  const [apptCount, setApptCount] = useState(0)

  const getCustomerAppointmentCount = async () => {
    try {
      const response = await axios.get(`appointment/count?customerId=${userData?._id}`)
      setApptCount(response?.data?.count || 0)
    } catch (error) {}
  }
  useEffect(() => {
    if (userData) {
      getCustomerAppointmentCount()
    }
  }, [userData])
  useEffect(() => {
    console.log('in get location	')
    getLocation()
  }, [])

  if (showStartPage) {
    return (
      <IonPage>
        <IonContent className='whiteBackground'>
          {/* <div style={{ flexDirection: 'column', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                        <h2>Would you like to book an appointment?</h2>
                        <IonButton onClick={() => { setShowStartPage(false) }} expand='block'> &nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;&nbsp;&nbsp;&nbsp; </IonButton>
                    </div> */}
          <HeroSection setShowStartPage={setShowStartPage} />
        </IonContent>
      </IonPage>
    )
  }
  console.log(apptCount, 'apptCountapptCount')
  return (
    <IonPage>
      {apptCount >= maxLocationAppts ? (
        <IonContent>
          <StandardContainer>
            <NoFutureApptsCard apptCount={apptCount} />
          </StandardContainer>
        </IonContent>
      ) : (
        <StepController
          isOpen={true}
          closeModal={() => {
            setShowStartPage(true)
          }}
          getAppointments={getAppointments}
          isWalkin={false}
          setSubscriptionReferrer={setSubscriptionReferrer}
        />
      )}
    </IonPage>
  )
}

function HeroSection({ setShowStartPage }) {
  return (
    <>
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <IonImg
          style={{ width: '100%', height: 800, objectFit: 'cover' }}
          src={'/assets/blackAndWhiteShop.png'}
        ></IonImg>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 600,
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            height: 500,
          }}
        >
          <h1
            style={{
              textShadow: '1px 1px 10px black',
              fontFamily: 'Montserrat, Arial',
              margin: 20,
              fontWeight: '800',
              fontSize: 40,
            }}
          >
            Yes! I want to{' '}
            <span style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 45 }}>
              #staycut
            </span>{' '}
            Book me an appointment!
          </h1>
          <h2></h2>
          <div style={{ margin: 10 }}>
            <IonButton
              onClick={() => {
                setShowStartPage(false)
              }}
              expand='block'
            >
              {' '}
              &nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            </IonButton>
          </div>
        </div>
      </div>
    </>
  )
}
