import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios'
import ClockIcon from '@mui/icons-material/AccessTime'
import { IonButton, IonList, IonRouterLink, IonSpinner } from '@ionic/react';
import StandardCenterCenter from '../commonComponents/StandardCenterContainer';
import { colors } from '../theme/colors';
import StandardContainer from '../commonComponents/standardContainer';

const styles = {
    floatingButton: {
        color: 'white',
        fontWeight: '600',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',

        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        padding: -1
    },
    buttonStyle: {
        margin: 0
    },
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
    }
}


export default function ServicesCatalog({ businessId, locationId }) {
    const [employee, setEmployee] = useState([])
    const [employees, setEmployees] = useState([])
    const [locationServices, setLocationServices] = useState()
    const [servicesSelected, setServicesSelected] = useState([])


    useEffect(() => { getEmployeesList() }, [])

    const getEmployeesList = async () => {

        let response = await axios.get(`/employee/getPublicListWithServices?businessId=${businessId}&locationId=${locationId}`)
        setEmployees(response.data)
        console.log(response.data)
    }

    useEffect(() => { getServicesList() }, [])

    const getServicesList = async () => {
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        let response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
        setLocationServices(response.data)
    }


    return (
        <>
            <ServicesList locationServices={locationServices} employees={employees} />
        </>
    );
}


function ServicesList({ locationServices, employees }) {
    if (!locationServices) return <div style={styles.loadingBox}><IonSpinner name='dots' /></div>

    // if (anyProfessional) {
    return (
        <StandardContainer>
            <div>
                <p style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 40, marginBottom: 0 }}>#YourStyle</p>
                <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: 0, textShadow: '1px 1px 10px black', color: 'white' }}>SERVICES</h1>

                <StandardCenterCenter>

                    <IonList inset={true} style={{ width: '100%' }}>


                        {locationServices &&
                            locationServices.map((row) => {
                                const isServiceAvailable = employees.some((employee) => {
                                    return (
                                        (employee.services &&
                                            employee.services.includes(row._id))
                                    );
                                });

                                if (isServiceAvailable) {
                                    return (
                                        <ServiceCard
                                            service={row}
                                            key={row._id}
                                        />
                                    );
                                }

                                return null;
                            })}


                    </IonList>
                </StandardCenterCenter>
                <StandardCenterCenter>
                    <IonRouterLink routerLink='/schedule' routerDirection='none'>
                        <div style={{ fontFamily: 'Montserrat, Arial', padding: 10, fontWeight: '600',  fontSize: 16, color: 'black', backgroundColor: colors.green, border: `1px solid ${colors.green}`, borderRadius: 6, maxWidth: 400,  minWidth:300, textAlign: 'center' }}>
                            Book Now
                        </div>
                    </IonRouterLink>
                </StandardCenterCenter>
                <br></br>
            </div>
        </StandardContainer>
    )



}

function ServiceCard({ service }) {

    let priceBoxStyles = { border: '1px solid grey' }

    let price = `$${service.price}`

    return (
        <>
            <ListItem>
                <ListItemText
                    primary={<h6 style={{ margin: 0 }}>{service.name}</h6>}
                    secondary={<div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13, color: 'grey' }}>{service.description}</span>
                        <span style={{ fontSize: 13, color: 'grey' }}>
                            <ClockIcon style={{ fontSize: 13, marginBottom: -2, marginRight: 4, color: colors.green }} />{service.durationMin} min</span>
                    </div>} />
                <span style={{ ...{ borderRadius: 4, padding: '4px 8px' }, ...priceBoxStyles }} >
                    <Typography variant="subtitle2">{price}</Typography>
                </span>
            </ListItem>
            <Divider component="li" />
        </>
    )
}

