import { IonButton, IonCard, IonContent, IonImg, IonItem, IonPage, IonTitle } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import Header from '../../commonComponents/header'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { colors } from '../../theme/colors'
import { AuthContext } from '../../auth'
import StandardContainer from '../../commonComponents/standardContainer'
import { trackPageEvent } from '../../analytics'
import PackagesListView from './PackagesListView'

export default function Subscription({ openRegisterModal }) {
  let businessId = process.env.REACT_APP_BUSINESS_ID
  let locationId = process.env.REACT_APP_LOCATION_ID
  const { userData, firebaseUser } = useContext(AuthContext)
  const [subscriptions, getSubscriptions] = useAxios(
    `/subscriptionPackage/publicGetList?businessId=${businessId}&locationId=${locationId}`,
  )

  useEffect(() => {
    trackPageEvent('SubscriptionsList')
  }, [])

  return (
    <IonPage>
      <Header title='Subscriptions' />
      <IonContent>
        <StandardContainer medium>
          {userData?.isSubscribed ? (
            <>
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <h2 style={{ marginTop: 0, color: colors.black, fontWeight: '600' }}>
                  Already a Subscriber
                </h2>
                <div style={{ fontSize: 14, fontWidth: '600' }}>
                  For more detail go to your account page{' '}
                </div>
                <br></br>
                <IonButton routerLink='/account' routerDirection='none'>
                  My Account
                </IonButton>
              </div>
            </>
          ) : (
            <>
              <PackagesListView
                packages={subscriptions?.data}
                openRegisterModal={openRegisterModal}
              />
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
