import { Grid, TextField } from '@mui/material'
import { useContext, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import axios from 'axios'
import { AuthContext } from '../../auth'

export default function EditPaneModal({
  userData,
  editUserOpen,
  closeEditPane,
  closeEditPaneAndRefreshData,
}) {
  const [tempUserData, setTempUserData] = useState(userData)
  const [customer, setCustomer] = useState()
  const [userDataChanged, setUserDataChanged] = useState(false)
  const [emailValidationMessage, setEmailValidationMessage] = useState(false)
  let customer_id = tempUserData?._id
  const { businessData, locationId } = useContext(AuthContext)

  const checkCustomerExists = async email => {
    try {
      let response = await axios.get(`/customer/checkEmail?email=${email}`)
      console.log(response, 'response=====================')
      return response
    } catch (error) {
      console.log(error, 'error=============================')
      return false
    }
  }
  const onValueChange = async (field, value) => {
    console.log(field, value, 'valueee=========')
    const userTempData = { ...tempUserData, [field]: value }
    console.log(userTempData, 'userTempData')
    setTempUserData({ ...tempUserData, [field]: value })
    setUserDataChanged(true)
    if (field == 'email' && value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emailPattern.test(value)
      if (isValid) {
        setEmailValidationMessage(false)
        try {
          const checkEmailExistsResponse = await checkCustomerExists(value)
          console.log(checkEmailExistsResponse, 'checkEmailExistsResponse')
          if (
            checkEmailExistsResponse.data &&
            checkEmailExistsResponse.data &&
            checkEmailExistsResponse.data.emailExists
          ) {
            //check if atleast one user has the provided email

            //if emailValidationMessage then seterror message as email already exists
            setEmailValidationMessage('Email Already In Use')
            setUserDataChanged(false)
          } else {
            setEmailValidationMessage(false)
          }
          // const checkIfUserExistsWithEmailResponse = await axios.get('/checkemailValidationMessage', {
          //   value,
          // })
          // console.log(checkIfUserExistsWithEmailResponse, 'checkIfUserExistsWithEmailResponse')
        } catch (error) {
          console.log(error, 'error')
        }
      } else {
        setEmailValidationMessage('Please Enter A Valid Email')
        setUserDataChanged(false)
      }
    } else if (field == 'email') {
      setEmailValidationMessage('Email Address is required')
      setUserDataChanged(false)
    }
  }
  const handleSave = async () => {
    console.log(tempUserData, 'tempUserData')
    try {
      let response = await axios.patch(`/customer?id=${customer_id}`, tempUserData)
      console.log(response, 'responseresponse')
      setTimeout(() => {
        closeEditPaneAndRefreshData()
      }, 200)
      // setUserDataChanged(false)
    } catch (error) {
      console.log('error in updating user')
      // setUserDataChanged(false)
    }
    // await getCustomer()
  }

  return (
    <IonModal isOpen={editUserOpen}>
      {console.log(emailValidationMessage, 'emailValidationMessage')}
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Update Your Details</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => closeEditPane()}>Close</IonButton>
            {!emailValidationMessage && userDataChanged && (
              <IonButton color={'primary'} onClick={() => handleSave()}>
                Save
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <Grid container padding={2} spacing={8}>
          <Grid item sm={12} md={8}>
            {/* <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
              <TextField
                margin='dense'
                id='firstName'
                label='First Name'
                type='text'
                variant='standard'
                value={tempUserData.firstName}
                onChange={e => {
                  onValueChange('firstName', e.target.value)
                }}
                style={{ flex: 1, marginRight: 10 }}
              />

              <TextField
                margin='dense'
                id='lastName'
                label='Last Name'
                type='text'
                variant='standard'
                value={tempUserData.lastName}
                onChange={e => {
                  onValueChange('lastName', e.target.value)
                }}
                style={{ flex: 1 }}
              />
            </div> */}
            {console.log(emailValidationMessage, 'emailValidationMessageemailValidationMessage')}
            <TextField
              error={emailValidationMessage}
              helperText={emailValidationMessage ? emailValidationMessage : ' '}
              margin='dense'
              id='email'
              label='Email'
              type='text'
              fullWidth
              variant='standard'
              value={tempUserData.email}
              onChange={e => {
                onValueChange('email', e.target.value)
              }}
              style={{ marginTop: 20 }}
            />
            {/* <p style={{ marginBottom: -20 }}>{tempUserData.email}</p> */}
            {/* <PhoneNumberTextField
              value={tempUserData.phoneNumber}
              onChange={e => {
                onValueChange('phoneNumber', e.target.value)
              }}
            /> */}
          </Grid>
        </Grid>
      </IonContent>
    </IonModal>
  )
}
