import React from 'react'

export default function ConversationPlaceholder({ count = 3 }) {
  const placeholders = Array.from({ length: count }, (_, index) => (
    <React.Fragment key={index}>
      <div className='mine messages' style={{ marginBottom: '1rem' }}>
        <div className='message  dots-placeholder'>
          <div className='dots'></div>
          <div className='dots'></div>
          <div className='dots'></div>
        </div>
      </div>
      <div className='yours messages' style={{ marginBottom: '1rem' }}>
        <div className='message  dots-placeholder'>
          <div className='dots'></div>
          <div className='dots'></div>
          <div className='dots'></div>
        </div>
      </div>
    </React.Fragment>
  ))

  return <div className='placeholder-container'>{placeholders}</div>
}
