import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/standardContainer'
import axios from 'axios'
import { closeCircleOutline } from 'ionicons/icons'

export default function ManageSubscription({ match }) {
  let subscriptionId = match?.params?.id
  const [subscription, setSubscription] = useState('')
  const [loading, setLoading] = useState(true)

  const getCustomerSubscription = async () => {
    if (subscriptionId) {
      try {
        setSubscription({ data: subscription.data, loading: true, error: null })
        let response = await axios.get(`/subscription/byCustomerId?id=${subscriptionId}`)
        if (response && response.data) {
          setSubscription(response.data)
          setLoading(false)
        }
      } catch (error) {
        setSubscription({
          data: null,
          loading: false,
          error: 'There was an issue getting your data',
        })
      }
    }
  }

  useEffect(() => {
    getCustomerSubscription()
  }, [])
  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Manage Subscription'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          {loading && (
            <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </IonRow>
          )}
          {!loading && (
            <>
              <IonList inset={true} style={{ margin: '0 0 1rem 0', padding: '1rem' }}>
                <IonGrid style={{ margin: 0, marginLeft: -10, color: 'black' }}>
                  <IonRow style={{ margin: 0 }}>
                    <IonCol size='auto'>
                      <div
                        style={{
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 45,
                          width: 45,
                          background: 'linear-gradient(to top right, #FFD700, #FFDF00, #FFEA00)',
                          borderRadius: 8,
                        }}
                      >
                        <h1 style={{ margin: 0, marginTop: -4 }}>∞</h1>
                      </div>
                    </IonCol>
                    <IonCol>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <h5 style={{ margin: 0, marginTop: 4 }}>
                          {subscription?.subscription?.name} Package
                        </h5>
                        <h6 style={{ margin: 0, fontWeight: '600', fontSize: 14 }}>
                          {subscription?.isCorporateDeal ? 'Corporate ' : null}Subscriber
                        </h6>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonList>
              <IonList inset={true} mode='ios' style={{ margin: 0 }}>
                <IonItem routerLink={`/cancelSubscription/${subscriptionId}`} detail={true}>
                  <IonIcon
                    style={{ color: 'orange', marginRight: '1rem' }}
                    icon={closeCircleOutline}
                  />
                  Cancel Subscription
                </IonItem>
              </IonList>
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
