import { useContext, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { home, calendar, location, person, list, repeat } from 'ionicons/icons'
import Home from './Home'
import Account from './account'
import Appointments from './Appointments'
import Location from './location'
import LocationDetails from './location/locationDetails'
import LoginModal from '../LoginModal'
import ScheduleModal from '../ScheduleModal'
import axios from 'axios'
import { AuthContext } from '../auth'
import AppointmentDetails from './AppointmentDetails'
import Schedule from './schedule'
import Subscription from './subscription'
import SubscriptionDetails from './subscription/detailsStripe'
import PrivacyPolicy from '../privacyPolicy'
import { Idfa } from '@sparkfabrik/capacitor-plugin-idfa'
import { Capacitor } from '@capacitor/core'
import AdsLandingPage from './AdsLandingPage'
import ManageSubscription from './subscription/ManageSubscription'
import CancelSubscription from './subscription/CancelSubscription'
import CustomerRating from '../Feedback'
import CustomerFeedback from '../Feedback/CustomerFeedback'
import MessagesListView from '../Messages/ListView'
import ConversationDetails from '../Messages/ConversationDetails'
const styles = {
  icon: {
    height: 22,
    width: 22,
  },
  tabButton: {
    backgroundColor: 'transparent',
  },
}

const Tabs = () => {
  const { userData, firebaseUser } = useContext(AuthContext)
  const [authModalData, setAuthModalData] = useState({ isOpen: false, viewType: 'register' })
  const [scheduleModalData, setScheduleModalData] = useState({ isOpen: false })
  const [appointments, setAppointments] = useState([])
  const [locations, setLocations] = useState([])
  const [checkIDFA, setCheckedIDFA] = useState(false)
  const [subscriptionReferrer, setSubscriptionReferrer] = useState(null)

  useEffect(() => {
    if (!checkIDFA) {
      if (Capacitor.isNativePlatform()) {
        try {
          Idfa.getAdvertisingInfo()
            .then(response => {
              if (response.isAdTrackingLimited === true) {
                //Add Tracking not allowed
                window['ga-disable-GA_MEASUREMENT_ID'] = true
              }
            })
            .catch(err => {
              console.error(err)
            })
        } catch (error) {}
      }

      setCheckedIDFA(true)
    }
  }, [])

  const businessId = process.env.REACT_APP_BUSINESS_ID
  useEffect(() => {
    getAppointments()
  }, [userData])

  function openRegisterModal() {
    setAuthModalData({ isOpen: true, viewType: 'register' })
  }

  function openLoginModal() {
    setAuthModalData({ isOpen: true, viewType: 'login' })
  }

  function openScheduleModal() {
    setScheduleModalData({ isOpen: true })
  }

  function openWalkinModal() {
    setScheduleModalData({ isOpen: true, type: 'walkin' })
  }

  async function getAppointments() {
    try {
      if (userData != 'loading' && userData) {
        let resp = await axios.get(`/customer/appointments`)

        setAppointments(resp.data)
      } else if (!firebaseUser) {
        setAppointments(null)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getLocations()
    const interval = setInterval(() => {
      getLocations()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  async function getLocations() {
    let d = await axios.get(`/location/publicGetListByBusinessId?businessId=${businessId}`)
    setLocations(d.data)
  }

  return (
    <>
      <IonTabs>
        <IonRouterOutlet ionPage>
          {/* <Route exact path="/home" render={(props) => (<Home {...props} openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} openScheduleModal={openScheduleModal} appointments={appointments} />)} /> */}
          <Route exact path='/home'>
            <Redirect to='/' />
          </Route>
          <Route exact path='/location'>
            <Location
              openScheduleModal={openScheduleModal}
              businessId={businessId}
              locations={locations}
            />
          </Route>
          <Route
            exact
            path='/home2'
            render={props => <AdsLandingPage {...props} locations={locations} />}
          />

          <Route path='/location/:id' component={LocationDetails} />
          <Route
            exact
            path='/appointment'
            render={props => (
              <Appointments
                {...props}
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
                openScheduleModal={openScheduleModal}
                appointments={appointments}
              />
            )}
          />
          <Route
            path='/appointment/:id'
            render={props => <AppointmentDetails {...props} getAppointments={getAppointments} />}
          />
          <Route
            path='/account'
            render={props => (
              <Account
                {...props}
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
              />
            )}
          />
          <Route
            exact
            path='/schedule'
            render={props => (
              <Schedule
                {...props}
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
                getAppointments={getAppointments}
                setSubscriptionReferrer={setSubscriptionReferrer}
              />
            )}
          />
          <Route
            exact
            path='/subscription'
            render={props => (
              <Subscription
                {...props}
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
              />
            )}
          />
          <Route
            path='/subscription/:id'
            render={props => (
              <SubscriptionDetails {...props} subscriptionReferrer={subscriptionReferrer} />
            )}
          />
          <Route
            path='/manageSubscription/:id'
            render={props => <ManageSubscription {...props} />}
          />
          <Route
            path='/cancelSubscription/:id'
            render={props => <CancelSubscription {...props} />}
          />
          <Route path='/privacypolicy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/review'>
            <CustomerRating />
          </Route>
          <Route path='/feedback'>
            <CustomerFeedback />
          </Route>
          <Route exact path='/messages' render={props => <MessagesListView />} />
          <Route path='/messages/:id' render={props => <ConversationDetails {...props} />} />

          <Route exact path='/'>
            <Home
              openLoginModal={openLoginModal}
              openRegisterModal={openRegisterModal}
              openScheduleModal={openScheduleModal}
              getAppointments={getAppointments}
              appointments={appointments}
              openWalkinModal={openWalkinModal}
              locations={locations}
            />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot='bottom' style={{ backgroundColor: 'white' }}>
          <IonTabButton tab='home' href='/home' style={styles.tabButton}>
            <IonIcon icon={home} style={styles.icon} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab='location' href='/location' style={styles.tabButton}>
            <IonIcon icon={location} style={styles.icon} />
            <IonLabel>Locations</IonLabel>
          </IonTabButton>
          <IonTabButton tab='schedule' href='/schedule' style={styles.tabButton}>
            <IonIcon icon={calendar} style={styles.icon} />
            <IonLabel>Schedule</IonLabel>
          </IonTabButton>
          <IonTabButton tab='appointment' href='/appointment' style={styles.tabButton}>
            <IonIcon icon={list} style={styles.icon} />
            <IonLabel>History</IonLabel>
          </IonTabButton>
          {userData?.isSubscribed ? null : (
            <IonTabButton tab='subscription' href='/subscription' style={styles.tabButton}>
              <IonIcon icon={repeat} style={styles.icon} />
              <IonLabel>Subscription</IonLabel>
            </IonTabButton>
          )}
          <IonTabButton tab='account' href='/account' style={styles.tabButton}>
            <IonIcon icon={person} style={styles.icon} />
            <IonLabel>Account</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <LoginModal authModalData={authModalData} setAuthModalData={setAuthModalData} />
      <ScheduleModal
        scheduleModalData={scheduleModalData}
        setScheduleModalData={setScheduleModalData}
        getAppointments={getAppointments}
      />
    </>
  )
}

export default Tabs
