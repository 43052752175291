import React, { useEffect, useState } from 'react';
import { IonButtons, IonButton, IonModal, IonContent, IonImg, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonIcon, IonToolbar, IonItem, IonLabel, IonList, IonCol, IonGrid, IonRow } from '@ionic/react';
import axios from 'axios';
import Login from './login2'
import Register from './Register';
import PasswordReset from './passwordReset';

export default function LoginModal({ authModalData, setAuthModalData }) {

    function handleClose() {
        setAuthModalData({ isOpen: false, viewType: authModalData.viewType })
    }

    function switchView(type) {
        if(type){
            setAuthModalData({ isOpen: true, viewType: type })
        }else {
            if (authModalData.viewType == 'register') {
                setAuthModalData({ isOpen: true, viewType: 'login' })
            } else {
                setAuthModalData({ isOpen: true, viewType: 'register' })
            }
        }
        
    }


    return (
        <IonModal isOpen={authModalData.isOpen}>
            <IonContent>
                {authModalData.viewType == 'register'
                    ? <Register cancel={handleClose} complete={handleClose} switchView={switchView} />
                    : authModalData.viewType == 'login'
                        ? <Login cancel={handleClose} complete={handleClose} switchView={switchView} />
                        : authModalData.viewType == 'passwordReset'
                            ? <PasswordReset cancel={handleClose} complete={handleClose} switchView={switchView} />
                            : null
                }
            </IonContent>
        </IonModal>
    )
}