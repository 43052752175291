import React, { useState, useEffect, useContext } from 'react'
import { Typography, Button, Paper, Card, Avatar, Grid, Divider } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import axios from 'axios'
import HeaderComponent from './headerComponent'
import ClockIcon from '@mui/icons-material/AccessTime'
import { IonButton, IonSpinner } from '@ionic/react'
import { trackScheduling } from '../analytics'

const styles = {
  floatingButton: {
    color: 'white',
    fontWeight: '600',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',

    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    padding: -1,
  },
  buttonStyle: {
    margin: 0,
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default function StepPickService({
  userSubscription,
  scheduleData,
  businessId,
  locationId,
  updateScheduleData,
  step,
  stepNext,
  stepBack,
  totalSteps,
  isWalkin,
  trackStep,
}) {
  const [employee, setEmployee] = useState([])
  const [employees, setEmployees] = useState([])
  const [locationServices, setLocationServices] = useState()
  const [servicesSelected, setServicesSelected] = useState([])

  useEffect(() => {
    let servicesList = []
    for (let s of scheduleData.services) {
      servicesList.push(s._id)
    }
    setServicesSelected(servicesList)
  }, [scheduleData])

  useEffect(() => {
    getEmployeesList()
  }, [])

  useEffect(() => {
    trackStep('Service', step, scheduleData)
  }, [])

  const getEmployeesList = async () => {
    // let response = await axios.get(`/employee/getPublicList?businessId=${businessId}&locationId=${scheduleData.location._id}`)
    // setEmployees(response.data)
    if (scheduleData?.barber?._id) {
      let response = await axios.get(`/employee/public?id=${scheduleData?.barber?._id}`)
      setEmployees([response.data])
    }
    if (scheduleData?.anyProfessional || isWalkin) {
      let response = await axios.get(
        `/employee/getPublicListWithServices?businessId=${businessId}&locationId=${scheduleData.location._id}`,
      )
      setEmployees(response.data)
    }
  }

  useEffect(() => {
    getServicesList()
  }, [])

  const getServicesList = async () => {
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    let response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
    setLocationServices(response.data)
  }

  function handleServiceSelection(service) {
    if (servicesSelected.includes(service._id)) {
      //Remove Service
      let servicesList = []
      for (let s of scheduleData.services) {
        if (s._id != service._id) {
          servicesList.push(s)
        }
      }
      updateScheduleData('services', servicesList)
    } else {
      //Add Service
      updateScheduleData('services', [...scheduleData.services, service])
    }
  }

  function confirmSelection() {
    let servicesList = []
    scheduleData?.services?.map((item, index) => {
      const checkIfBarberHasService = scheduleData?.barber?.servicesInfo?.find(
        (barberService, index) => barberService._id == item._id,
      )
      let updatedService = { ...item }
      if (checkIfBarberHasService) {
        updatedService['durationMin'] = checkIfBarberHasService.durationMin
      } else {
        const service = locationServices.find(
          (locationService, index) => locationService._id == item._id,
        )
        updatedService['durationMin'] = service.durationMin
      }
      servicesList.push(updatedService)
    })

    updateScheduleData('services', servicesList)
    stepNext()
  }

  return (
    <>
      <ServicesList
        scheduleData={scheduleData}
        userSubscription={userSubscription}
        locationServices={locationServices}
        employees={employees}
        handleServiceSelection={handleServiceSelection}
        servicesSelected={servicesSelected}
        isWalkin={isWalkin}
      />
      <NextButton confirmSelection={confirmSelection} servicesSelected={servicesSelected} />
    </>
  )
}

function NextButton({ confirmSelection, servicesSelected }) {
  if (servicesSelected.length <= 0) return null
  return (
    <div style={styles.floatingButton}>
      <IonButton expand='full' onClick={confirmSelection} style={styles.buttonStyle}>
        Next
      </IonButton>
    </div>
  )
}

function ServicesList({
  locationServices,
  employees,
  handleServiceSelection,
  servicesSelected,
  userSubscription,
  isWalkin,
  anyProfessional,
  scheduleData,
}) {
  if (!locationServices || (!isWalkin && !employees))
    return (
      <div style={styles.loadingBox}>
        <IonSpinner name='dots' />
      </div>
    )

  // if (anyProfessional) {
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {locationServices &&
          locationServices.map(row => {
            const isServiceAvailable = employees.some(employee => {
              return employee.services && employee.services.includes(row._id)
            })

            if (isServiceAvailable) {
              return (
                <ServiceCard
                  scheduleData={scheduleData}
                  userSubscription={userSubscription}
                  service={row}
                  handleServiceSelection={handleServiceSelection}
                  key={row._id}
                  servicesSelected={servicesSelected}
                />
              )
            }

            return null
          })}
      </List>
    </Grid>
  )
  // }
  // else {
  //     return (
  //         <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
  //             <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
  //                 {locationServices && locationServices.map((row) => {
  //                     if (isWalkin || (employee?.services && employee?.services?.includes(row._id))) {
  //                         return <ServiceCard userSubscription={userSubscription} service={row} handleServiceSelection={handleServiceSelection} key={row._id} servicesSelected={servicesSelected} />
  //                     }
  //                 })}
  //             </List>
  //         </Grid>
  //     )
  // }
}

function ServiceCard({
  scheduleData,
  service,
  handleServiceSelection,
  servicesSelected,
  userSubscription,
}) {
  let priceBoxStyles = { border: '1px solid grey' }
  if (servicesSelected.includes(service._id)) {
    priceBoxStyles = {
      backgroundColor: '#2175ea',
      border: '1px solid #2175ea',
      color: 'white',
    }
  }

  let price = `$${service.price}`
  if (userSubscription) {
    if (
      service.includedInSubscriptions.some(
        subscription => subscription._id === userSubscription.subscriptionPackageId,
      )
    ) {
      price = 'Free'
    }
  }
  const checkIfBarberHasService = scheduleData?.barber?.servicesInfo?.find(
    (item, index) => item._id == service._id,
  )
  let updatedService = { ...service }
  if (checkIfBarberHasService) {
    updatedService['durationMin'] = checkIfBarberHasService.durationMin
  }

  return (
    <>
      <ListItem
        onClick={() => {
          handleServiceSelection(updatedService)
        }}
        style={{ cursor: 'pointer' }}
      >
        <ListItemText
          primary={<h6 style={{ margin: 0 }}>{updatedService.name}</h6>}
          secondary={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 13, color: 'grey' }}>{updatedService.description}</span>
              <span style={{ fontSize: 13, color: 'grey' }}>
                <ClockIcon
                  style={{ fontSize: 13, marginBottom: -2, marginRight: 4, color: '#2175EA' }}
                />
                {checkIfBarberHasService && checkIfBarberHasService.durationMin
                  ? checkIfBarberHasService.durationMin
                  : updatedService.durationMin}{' '}
                min
              </span>
            </div>
          }
        />
        <span style={{ ...{ borderRadius: 4, padding: '4px 8px' }, ...priceBoxStyles }}>
          <Typography variant='subtitle2'>{price}</Typography>
        </span>
      </ListItem>
      <Divider component='li' />
    </>
  )
}
