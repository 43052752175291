import { IonContent, IonPage } from "@ionic/react";
import StandardContainer from "./commonComponents/standardContainer";

export default function PrivacyPolicy() {
    return (
        <IonPage>
            <IonContent>
                <StandardContainer style={{padding:10}}>
                    <div>
                        <h3> PRIVACY POLICY</h3>

                        <p>Last Updated: July 5, 2023</p>

                        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you use our app or services provided by ClubCuts, LLC(the "Company," "we," "us," "our").This Privacy Policy applies to all users of our app and website.</p>

                        <p>1. INFORMATION WE COLLECT</p>

                        We collect several types of information from and about users of our app, including information:

                        By which you may be personally identified, such as your name, postal address, e - mail address, phone number, or any other identifier by which you may be contacted online or offline("personal information");
                        About your internet connection, the equipment you use to access our app and usage details.
                        We collect this information:

                        Directly from you when you provide it to us;
                        Automatically as you navigate through the app.Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies; and
                        From third parties, for example, our business partners.
                        <p>2. HOW WE USE YOUR INFORMATION</p>

                        We use information that we collect about you or that you provide to us, including any personal information:

                        To present our app and its contents to you;
                        To provide you with information, products, or services that you request from us;
                        To fulfill any other purpose for which you provide it;
                        To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
                        To notify you about changes to our app or any products or services we offer or provide through it;
                        In any other way we may describe when you provide the information;
                        For any other purpose with your consent.
                        <p>3. DISCLOSURE OF YOUR INFORMATION</p>

                        We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.We may disclose personal information that we collect or you provide as described in this privacy policy:

                        To our subsidiaries and affiliates;
                        To contractors, service providers, and other third parties we use to support our business;
                        To fulfill the purpose for which you provide it;
                        For any other purpose disclosed by us when you provide the information;
                        With your consent.
                        <p>4. CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</p>

                        We strive to provide you with choices regarding the personal information you provide to us.We have created mechanisms to provide you with the following control over your information:

                        Tracking Technologies and Advertising.You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent.To understand how you can manage your privacy settings, please refer to the documentation of your specific browser.
                        Promotional Offers from the Company.If you do not wish to have your e - mail address used by the Company to promote our own or third parties' products or services, you can opt-out by checking the relevant box located on the form where we collect your data or by sending us an email stating your request to team@clubcuts.com.
                        <p>5. DATA SECURITY</p>

                        We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.The safety and security of your information also depends on you.Where we have given you(or where you have chosen) a password for access to certain parts of our app, you are responsible for keeping this password confidential.

                        <p>6. CHANGES TO OUR PRIVACY POLICY</p>

                        It is our policy to post any changes we make to our privacy policy on this page.The date the privacy policy was last revised is identified at the top of the page.You are responsible for periodically visiting our app and this privacy policy to check for any changes.

                        <p>7. CONTACT INFORMATION</p>

                        To ask questions or comment about this privacy policy and our privacy practices, contact us at team @clubcuts.com

                        <p>8. USERS OUTSIDE THE UNITED STATES</p>

                        Our app is hosted in the United States.If you are accessing the app from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated.By using our services, you understand and agree that your information may be transferred to our facilities and those third parties with whom we share it as described in this privacy policy.

                        <p>9. CHILDREN UNDER THE AGE OF 13</p>

                        Our app is not intended for children under 13 years of age.No one under age 13 may provide any personal information to or on the app.We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this app or on or through any of its features.If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.

                        By using the ClubCuts, LLC app, you consent to the terms of this Privacy Policy.We reserve the right to modify this policy at any time, so please review it frequently to stay informed of any changes.

                        This policy was last updated on July 5, 2023.
                    </div>
                </StandardContainer>
            </IonContent>
        </IonPage>
    )
}