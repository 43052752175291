import { IonBackButton, IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';


export default function Header({ title, anotherToolbar }) {

  return (
    <IonHeader>
      <IonToolbar color="white" style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
        <IonButtons slot="start" style={{ color: 'black' }}>
          <IonBackButton />
        </IonButtons>
        <IonTitle class="ion-text-center" style={{ color: 'black' }}>
          {title}
        </IonTitle>
      </IonToolbar>
      {anotherToolbar ? anotherToolbar : null}
    </IonHeader>
  );
}