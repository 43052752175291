import React from 'react'
import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'

const PhoneNumberInput = ({ value, onChange }) => {
  const handleInputChange = event => {
    onChange(event)
  }

  return (
    <InputMask mask='(999) 999-9999' maskChar={null} value={value} onChange={handleInputChange}>
      {() => (
        <TextField
          type='tel'
          label='Phone Number'
          variant='standard'
          placeholder='(303) 000-0000'
          fullWidth
          style={{ marginTop: 20 }}
        />
      )}
    </InputMask>
  )
}

export default PhoneNumberInput
