import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { IonIcon, IonAlert, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { personCircle } from "ionicons/icons";

function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const PasswordReset = ({ switchView, removeHeader }) => {
  const [email, setEmail] = useState();
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleReset = async () => {
    setSending(true)
    if (!email) {
      setMessage("Please enter a valid email");
      setIserror(true);
      setSending(false)
      return;
    }
    if (validateEmail(email) === false) {
      setMessage("Your email is invalid");
      setIserror(true);
      setSending(false)
      return;
    }

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setMessage("Password reset has been sent. Please check your spam folder for an email");
        setEmail("");
        setSending(false);
        setCompleted(true);
      })
      .catch((error) => {
        // An error happened.
        setMessage("Failed to send reset instructions");
        setSending(false)
        setIserror(true)
      });
  }

  return (
    <IonPage>
      {removeHeader ? null :
      <IonHeader>
        <IonToolbar color="#fffffe">
          <IonTitle>Password Reset</IonTitle>
        </IonToolbar>
      </IonHeader>
      }
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          {!completed ? (
            <>
              <IonRow>
                <IonCol>
                  <IonIcon
                    style={{ fontSize: "70px", color: "#0040ff", marginTop:20 }}
                    icon={personCircle}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h4 style={{marginTop:0}}>Password Reset</h4>
                  </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating"> Email</IonLabel>
                    <IonInput
                      name='email'
                      type="email"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value)}
                    >
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" disabled={sending} onClick={handleReset}>{sending ? <IonSpinner name='dots' /> : "Reset Password"}</IonButton>
                </IonCol>
              </IonRow>
            </>
          ) : (
            <>
              <IonRow>
                <IonCol>
                  <p>{message}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={() => switchView("login")}>Return to Login</IonButton>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
        {completed ? null : 
        <p style={{ fontSize: "medium" }}>
          <a onClick={() => { switchView('login') }}> Back to Login</a>
        </p>}
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
