import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import React from 'react'

export default function CommonHeader({
  title,
  backIcon,
  closeButton,
  closeModal,
  handleSaveButton,
  saveButton,
  saveDisabled,
}) {
  return (
    <IonHeader>
      <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
        <IonTitle class='ion-text-center'>{title}</IonTitle>
        <IonButtons slot='start'>
          {backIcon && <IonBackButton />}
          {closeButton && <IonButton onClick={closeModal}>Close</IonButton>}
        </IonButtons>
        <IonButtons slot='end'>
          {saveButton && (
            <IonButton disabled={saveDisabled} onClick={handleSaveButton} color={'primary'}>
              Save
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}
