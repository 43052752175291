export default function StandardContainer({ children, padding, medium }){
    let p = padding===0 ? 0 : (padding || 20)

    let w = 800
    if(medium){
        w = 600
    }

    return (
        <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
            <div style={{ maxWidth: w, width: '100%', padding:p }}>
                {children}
            </div>
        </div>
    )
}