import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'
axios.defaults.headers.common['cc-appname'] = "customer"
if(process.env.REACT_APP_ENVIRONMENT == 'develop'){
  axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:9000`
} else if(process.env.REACT_APP_ENVIRONMENT == 'appProd'){
  axios.defaults.baseURL = `https://app.clubcuts.com/api/`
} else {
  axios.defaults.baseURL = '/api/';
}



// axios.interceptors.request.use(request => {
//   console.log(request);
//   // Edit request config
//   return request;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
//   console.log(response);
//   // Edit response config
//   return response;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
