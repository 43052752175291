import { IonCard, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import StandardContainer from "../commonComponents/standardContainer";
import StandardCenterCenter from "../commonComponents/StandardCenterContainer";
import { colors } from "../theme/colors";

export default function Gallery() {

    let imagesList = [
        "/assets/gallery/bf3.png",
        "/assets/gallery/long.jpg",
        "/assets/gallery/g2.jpg",
        "/assets/gallery/hc1.png",
        "/assets/gallery/hc2.png",
        "/assets/gallery/bf1.jpg",
    ]

    return (
        <>
            <StandardContainer>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, }}>GALLERY</h1>
            <h6 style={{color: colors.black, marginTop:-10, marginBottom:24 }}>A small sample of what we do</h6>
                <IonGrid style={{ margin: -12 }}>
                    <IonRow style={{ margin: 0 }}>
                        {
                            imagesList?.map((i) => {
                                return (
                                    <IonCol key={i}>
                                        <StandardCenterCenter>
                                            <IonCard style={{ minWidth: 140, margin: 0, alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={i} style={{ objectFit: 'cover', width: '100%', objectPosition: 'center', marginBottom:-10 }} />
                                            </IonCard>
                                        </StandardCenterCenter>
                                    </IonCol>
                                )
                            })
                        }

                    </IonRow>
                </IonGrid>
            </StandardContainer>
        </>
    )
}