import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box } from '@mui/material';
import axios from 'axios'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ChevronLeft';
import LocationIcon from '@mui/icons-material/LocationOn';
import HeaderComponent from './headerComponent';


export default function StepPickLocation({locationId, businessId, updateScheduleData, stepNext, stepBack, totalSteps}) {
    const [locations, setLocations] = useState([])

    useEffect(getLocationsList,[])

    async function getLocationsList(){
        try {
            let response = await axios.get(`/location/getList?businessId=${businessId}`)
            setLocations(response.data)
        
        } catch (error) {
            console.log(error)   
        }
    }

    function handleLocationSelection(location){
        updateScheduleData('location', location)
        stepNext()
    }



    return (
        <>
        {/* <HeaderComponent title={'Pick a Location'} handleBack={()=>{setStep('customer')}} progress={{total:5, step:1}}/> */}
            
            
            <Grid container style={{display:'flex', justifyContent:'center'}}>
                <Grid item container style={{display:'flex', justifyContent:'center'}}>
                    {locations.map(e=>(
                        <LocationCard key={e._id} location={e} handleLocationSelection={handleLocationSelection}/>
                    ))}
                </Grid>
            </Grid>

           
        </>
    );
}


function LocationCard({location, handleLocationSelection}){
    return (
        <Grid item style={{width:'100%', cursor:'pointer'}} onClick={()=>{handleLocationSelection(location)}}>
            <Card style={{ display:'flex', flexDirection:'column', margin:5, padding:20}}>
                <Grid container>
                    <Grid item>
                        <div style={{margin:10, marginRight:20, marginTop:6}}>
                            <Avatar>
                                <LocationIcon />
                            </Avatar>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <Typography style={{marginTop:0, fontWeight:'600'}} variant={'subtitle1'}>{location.name}</Typography>
                            <Typography style={{fontSize:12, marginTop:-4, color:'grey'}} variant={'p'}>{location.addressStreet1}</Typography>
                            <Typography style={{fontSize:12, marginTop:-4, color:'grey'}} variant={'p'}>{location.addressCity}, {location.addressState} {location.addressZipcode}</Typography>
                        </div>
                    </Grid>
                </Grid>

            </Card>
        </Grid>
    )
}
