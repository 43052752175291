import { IonCard } from '@ionic/react'
import React, { useContext } from 'react'
import { AuthContext } from '../auth'

export default function NoFutureApptsCard({ apptCount }) {
  const { locationData } = useContext(AuthContext)
  const maxLocationAppts = locationData?.appointmentSettings?.futureAppointmentsLimit
  return (
    <IonCard className='booking-limit-card'>
      <p className='error-message'>
        Sorry, you cannot book more than {maxLocationAppts} appointment
        {maxLocationAppts > 1 ? 's' : ''} in the future.
      </p>
      <p className='error-message'>
        You already have {apptCount} appointment
        {apptCount !== 1 ? 's' : ''} in the future.
      </p>
      <p className='help-message'>
        If you have any questions or need assistance, please contact our support team.
      </p>
    </IonCard>
  )
}
