import { IonButton, IonCard, IonContent, IonPage, IonSpinner, IonTextarea } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import FeedbackRating from './CustomerRating'
import { AuthContext } from '../auth'
import StandardContainer from '../commonComponents/standardContainer'
import axios from 'axios'
import { useHistory } from 'react-router'

export default function CustomerRating() {
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState('')
  const { businessData, userData, locationData, getUserData } = useContext(AuthContext)
  const [submittingRating, setSubmittingRating] = useState(false)
  const [feedbackSectionVisible, setFeedbackSectionVisible] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  const history = useHistory()
  const handleRatingChange = newRating => {
    setRating(newRating)
    setFeedbackSectionVisible(false)
  }

  const handleRatingSubmit = async () => {
    setSubmittingRating(true)
    const urlParams = new URLSearchParams(window.location.search)

    try {
      const ratingInput = {
        customerId: userData?._id, // Extracts the value of 'id' parameter
        reviewId: urlParams.get('id'), // Extracts the value of 'reviewId' parameter
        rating: rating, // You can set a default value or extract it from the URL if needed
        businessId: urlParams.get('businessId'), // Extracts the value of 'businessId' parameter
        locationId: urlParams.get('locationId'), // Extracts the value of 'locationId' parameter
      }
      const response = await axios.post('/location/review', ratingInput)

      if (response.status === 200) {
        setSubmittingRating(false)
        if (rating <= locationData?.deflectionSettings?.deflections) {
          history.push(`/feedback${window.location.search}&rating=${rating}`)
        } else {
          // Open a new tab with the redirect link
          const redirectLink = urlParams.get('websiteLink')
          setFeedbackSubmitted(true)
          window.open(redirectLink, '_blank')
          // You can also handle other actions or submit logic here
        }
      }
    } catch (error) {}
    // Handle rating submit logic here
  }
  const updateReviewClick = async () => {
    const urlParams = new URLSearchParams(window.location.search)

    const ratingInput = {
      customerId: userData?._id, // Extracts the value of 'id' parameter
      reviewId: urlParams.get('id'), // Extracts the value of 'reviewId' parameter
      businessId: urlParams.get('businessId'), // Extracts the value of 'businessId' parameter
      locationId: urlParams.get('locationId'), // Extracts the value of 'locationId' parameter
    }

    try {
      const response = await axios.post('/location/review/click', ratingInput)
    } catch (error) {}
  }

  useEffect(() => {
    updateReviewClick()
  }, [])

  return (
    <IonPage>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ marginTop: '5rem', padding: '1rem' }}>
            {!feedbackSubmitted && (
              <>
                <h1 style={{ fontWeight: 'bold', color: 'black' }}>
                  Thanks for coming to {businessData?.name} Please rate your experience
                </h1>
                <FeedbackRating rating={rating} onRatingChange={handleRatingChange} />
                <IonButton disabled={rating === 0} expand='full' onClick={handleRatingSubmit}>
                  {submittingRating && <IonSpinner name='lines' />}
                  Submit
                </IonButton>
              </>
            )}
            {feedbackSectionVisible && rating < 3 && (
              <>
                <h1 style={{ fontWeight: 'bold', color: 'black' }}>
                  Please tell us more about how we can improve your experience
                </h1>
                <IonTextarea
                  style={{
                    height: '13rem',
                    border: '1px solid #bfbfbf',
                    borderRadius: '10px',
                    padding: '1rem',
                    marginBottom: '1rem',
                    marginTop: '2rem',
                  }}
                  value={feedback}
                  placeholder='Please tell us more...'
                  onIonChange={e => setFeedback(e.detail.value)}
                />
                <IonButton disabled={!feedback.trim()} expand='full'>
                  Submit Feedback
                </IonButton>
              </>
            )}
            {feedbackSubmitted && (
              <>
                <h2>Thank You for Your Feedback!</h2>
                <p>
                  We greatly appreciate you taking the time to share your thoughts with us. Your
                  feedback is invaluable and helps us improve our services to better meet your
                  needs.
                </p>
                <p>
                  Our team is dedicated to delivering the best experience possible, and your
                  insights contribute to that ongoing effort. If you have any further comments or
                  suggestions, please feel free to reach out to us.
                </p>
                <IonButton routerLink='/' style={{ width: '100%' }}>
                  Back To Home
                </IonButton>
              </>
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
