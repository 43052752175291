import React from 'react';
import { Typography } from '@mui/material';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import Card from './card';


export default function AppointmentCard({ appointment }) {

  let subtitle = "FUTURE APPOINTMENT"
  let statusColor = 'grey'

  if (appointment.status == 'Complete' || appointment.status == "Closed") {
    subtitle = "APPOINTMENT"
    statusColor = '#2175ea'
  }

  if (appointment.status == 'Canceled') {
    subtitle = "CANCELED APPOINTMENT"
    statusColor = '#ea6d21'
  }



  return (
    <div key={appointment._id} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: 15, paddingRight: 15, marginBottom: -30, marginTop: -30, color: 'black' }}>
      <Card key={appointment._id} routerDirection="forward" routerLink={`/appointment/${appointment._id}`}>
        <IonCardHeader>
          <IonCardSubtitle>{appointment?.status}</IonCardSubtitle>
          <IonCardTitle>{appointment?.services?.map((a) => {
            return (
              <span>{a?.name}&nbsp;</span>
            )
          })}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p style={{ marginTop: -10 }}>Professional: {appointment.anyProfessional ? "Any Professional" : `${appointment?.barber?.firstName} ${appointment?.barber?.lastName?.charAt(0)}`}</p>
          <p>{appointment?.location?.name} Location</p>
          {/* <p>{appointment.location.addressStreet1}</p>
          <p>{appointment.location.addressCity}, {appointment.location.addressState} {appointment.location.addressZipcode}</p> */}
          {/* <div style={{ height: 10, width: '100%' }}></div> */}
          <p style={{ fontWeight: (appointment?.status === 'Scheduled' ? '700' : '400'), color: (appointment?.status === 'Scheduled' ? '#2175ea' : 'grey') }}>{appointment?.selectedDate} at {appointment?.startTimeText}</p>
          <div style={{ fontFamily: 'Montserrat, Arial', padding: 10, fontWeight: '600', fontSize: 16, color: 'black', backgroundColor: '#f2f2f2', border: `1px solid white`, borderRadius: 6, width: '100%', textAlign: 'center', marginTop: 10, marginLeft:-4, marginRight:-4 }}>
            Details
          </div>
        </IonCardContent>
      </Card>
    </div>
  )
}