import React, { useEffect, useState, useContext } from 'react';
import { IonButtons, IonButton, IonModal, IonContent, IonImg, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonIcon, IonToolbar, IonItem, IonLabel, IonList, IonCol, IonGrid, IonRow } from '@ionic/react';
import axios from 'axios';
import SchedulingController from './Scheduler/stepController'
import { AuthContext } from './auth'

export default function ScheduleModal({scheduleModalData, setScheduleModalData, getAppointments}) {
    const { userData, firebaseUser } = useContext(AuthContext);

    function handleClose() {
        setScheduleModalData({isOpen:false})
    }

    let businessId = process.env.REACT_APP_BUSINESS_ID
    let locationId = process.env.REACT_APP_LOCATION_ID
    let customerId = userData && userData._id || null


    return (
        <IonModal isOpen={scheduleModalData.isOpen}>
            <IonContent>
                <SchedulingController isOpen={scheduleModalData.isOpen} businessId={businessId} locationId={locationId} customerId={customerId} isWalkin={scheduleModalData?.type == "walkin"} closeModal={handleClose} getAppointments={getAppointments}/>
            </IonContent>
        </IonModal>
    )
}