import { IonCard, IonIcon } from '@ionic/react';
import { calendar } from 'ionicons/icons';
import {colors} from '../theme/colors'
import Card from './card';


export default function BookButton({ openScheduleModal }) {

    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Card showBorder={true} onClick={openScheduleModal} >
          <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', padding: 10 }}>
            <IonIcon icon={calendar} color="primary" style={{ height: 20, width: 20, margin: 10 }} />
            <div style={{ fontSize: 20, fontWeight: 600, color: 'black' }}>Book Appointment</div>
          </div>
        </Card>
      </div>
    )
  }


