import React from 'react'

export default function NoConversationSelectedMessage() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '3rem',
      }}
    >
      <div className='no-conversation'>
        <h3>No conversation selected</h3>
        <p>Please select a conversation to view messages</p>
      </div>
    </div>
  )
}
